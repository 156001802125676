import React, {useContext, useEffect, useState} from 'react';
import './Alarm.scss'
import {useNavigate} from "react-router-dom";
import mqttRequests from "../../Utils/mqttRequests";
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import {Alert, Snackbar} from "@mui/material";
import moment from "moment";
import {SettingsContext} from "../../context/SettingsContext";

//the page with all the alarms
function GetAlarms(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('alarms');

    //back button effect
    window.addEventListener('popstate', () => {
        navigate("/")
    }, false);

    //to navigate through the app
    let navigate = useNavigate();

    //to display popups
    const [successOpen, setSuccessOpen] = useState(false);
    const [errTwoOpen, setErrTwoOpen] = useState(false);

    //set variable to re get the data
    const [reshow, setReshow] = useState(false);

    //to contains data
    const [pendingAlarms, setPendingAlarms] = useState([]);
    const [oldAlarms, setOldAlarms] = useState([]);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the temperature unit from the settings
    const {temperatureUnit} = useContext(SettingsContext);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getAllAlarms");
            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllAlarms");
            //publish a demand to get all the alarms
            mqttRequests.publish(mqttClient, "alarm/getAllAlarms", data);


            //read incoming message and send response to a function to refactor them
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //if it has alarms, it's to load data
                if(jsonParsed.alarms){
                    if(jsonParsed.result===1){
                        refactorData(jsonParsed.alarms);
                    }
                //else, it's to release the alarm
                }else if(jsonParsed.result){
                    if(jsonParsed.result === 1){
                        setSuccessOpen(true);
                        setReshow(!reshow);
                    }else {
                        setErrTwoOpen(true);
                    }
                }
            })
        }
    },[source, mqttClient, idAccount, reshow]);

    //set the data ready to display
    const refactorData = (data) => {
        if(data.length !== 0){
            //get all the alarms in an array
            data = data.sort((a,b) => a.nameMission > b.nameMission ? 1 : -1);
            let tempOld = [];
            let tempPending = [];

            //sort alarms if they are pending or not
            data.forEach((element)=> {
                if(element.acknowledge === "1"){
                    tempPending.push(element);
                }else {
                    tempOld.push(element);
                }
            })

            //prepare variables
            let tempSortedAlarm = [];
            let actualMissionName = "";
            let tempAlarmForMission = [];

            //create an array of array, each mission have an array with all its alarms (for pending alarms)
            if(tempPending.length !== 0){
                tempSortedAlarm = [];
                actualMissionName = tempPending[0].nameMission;
                tempAlarmForMission = [];
                tempPending.sort((a,b) => a.nameMission > b.nameMission ? 1 : -1).forEach((mission) => {
                    if(mission.nameMission === actualMissionName){
                        tempAlarmForMission.push(mission);
                    }else {
                        tempSortedAlarm.push(tempAlarmForMission);
                        tempAlarmForMission = [mission];
                        actualMissionName = mission.nameMission;
                    }
                })
                tempSortedAlarm.push(tempAlarmForMission);

                //set the variable for the display
                setPendingAlarms(tempSortedAlarm);
            }else{
                setPendingAlarms([]);
            }


            //create an array of array, each mission have an array with all its alarms (for old alarms)
            if(tempOld.length !== 0){
                tempSortedAlarm = [];
                actualMissionName = tempOld[0].nameMission;
                tempAlarmForMission = [];
                tempOld.sort((a,b) => a.nameMission > b.nameMission ? 1 : -1).forEach((mission) => {
                    if(mission.nameMission === actualMissionName){
                        tempAlarmForMission.push(mission);
                    }else {
                        tempSortedAlarm.push(tempAlarmForMission);
                        tempAlarmForMission = [mission];
                        actualMissionName = mission.nameMission;
                    }
                })
                tempSortedAlarm.push(tempAlarmForMission);

                //set the variable for the display
                setOldAlarms(tempSortedAlarm);
            }
        }
    }

    //to ask mqtt to release an alarm
    const handleRelease = (alarm) => {
        //generate operation code
        const operation = mqttRequests.generateOperationCode("releaseAlarm");
        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAlarm":"' + alarm.idAlarm + '", "idMissionSupervision":"' + alarm.idMissionSupervision + '"}';
        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/releaseAlarm");
        //publish a demand to get all the kinetics
        mqttRequests.publish(mqttClient, "alarm/releaseAlarm", data);
    }

    return(
        <div className="baseMainDiv">
            <h2 className="baseTitle">Alarm History</h2>
            <h3 className="alarmSubTitle">Pending alarms</h3>
            {pendingAlarms.length !== 0 ?
                pendingAlarms.map(function (groupAlarms, id) {
                    return (
                        <div key={id} className="alarmDivMarginLeft">
                            <h4 className="alarmSubTitle">Mission: {groupAlarms[0].nameMission}</h4>
                            <div className="alarmDivFlexMarginLeft">
                                <table className="alarmTable">
                                    <thead>
                                    <tr>
                                        <th className="alarmTableText">On device</th>
                                        <th className="alarmTableText">device connected</th>
                                        <th className="alarmTableText">Kinetic</th>
                                        <th className="alarmTableText">Trigger</th>
                                        <th className="alarmTableText">Trigger time</th>
                                        <th className="alarmTableText">Action</th>
                                    </tr>
                                    </thead>
                                    {groupAlarms.map((alarm, subId) => {
                                        return (
                                            <tbody key={subId}>
                                                <tr className="alarmDivTxt">
                                                    <td>
                                                        <h4 className="alarmTableText">{alarm.deviceName} (identifier: {alarm.deviceIdentifier})</h4>
                                                    </td>
                                                    <td>
                                                        {alarm.gatewayIdentifier === "mv" || alarm.gatewayIdentifier === "external" || alarm.gatewayIdentifier === "gateway"?
                                                            <p className="alarmTableText">The gateway</p>
                                                            :
                                                            alarm.gatewayIdentifier !== ""?
                                                                <p className="alarmTableText">device connected: {alarm.gatewayIdentifier}</p>
                                                                :
                                                                <p className="alarmTableText">-</p>
                                                        }
                                                    </td>
                                                    <td>
                                                        <p className="alarmTableText">-</p>
                                                    </td>
                                                    <td>
                                                        {alarm.datatype !== "Crash" ?
                                                            alarm.limiteType === 3 ?
                                                                <p>The device did not send points during {alarm.limite} minutes</p>
                                                                :
                                                            <p className="alarmTableText">{alarm.datatype} {alarm.limiteType === 1? "greater than " : "lower than "}
                                                                {alarm.datatype === "Temperature" ?
                                                                    temperatureUnit === "F" ?
                                                                        (alarm.limite* 9 / 5) + 32
                                                                        :
                                                                        temperatureUnit === "K" ?
                                                                            alarm.limite+273.15
                                                                            :
                                                                            alarm.limite
                                                                    :
                                                                    alarm.limite}
                                                            </p>
                                                            :
                                                            <p className="alarmTableText">A crash has occurred</p>
                                                        }
                                                    </td>
                                                    <td>
                                                        <p className="alarmTableText">{moment(new Date(alarm.dateAlarm*1000)).format("YYYY MMMM DD, HH:mm")}</p>
                                                    </td>
                                                    <td>
                                                        <button onClick={() => handleRelease(alarm)} className="alarmButton">Release</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            )
                                    })}

                                </table>
                            </div>
                        </div>
                    )
                })
                :
                <p className="alarmText">No pending alarms for the moment</p>
            }

            <h3 className="alarmSubTitle">Old alarms</h3>
            {oldAlarms.length !== 0 ?
                oldAlarms.map(function (groupAlarms, id) {
                    return (
                        <div key={id} className="alarmDivMarginLeft">
                            <h4 className="alarmSubTitle">Mission: {groupAlarms[0].nameMission}</h4>
                            <div className="alarmDivFlexMarginLeft">
                                <table className="alarmTable">
                                    <thead>
                                    <tr>
                                        <th className="alarmTableText">On device</th>
                                        <th className="alarmTableText">device connected</th>
                                        <th className="alarmTableText">Kinetic</th>
                                        <th className="alarmTableText">Trigger</th>
                                        <th className="alarmTableText">Trigger time</th>
                                    </tr>
                                    </thead>
                                    {groupAlarms.map((alarm, subId) => {
                                        return (
                                            <tbody key={subId}>
                                            <tr className="alarmDivTxt">
                                                <td>
                                                    <h4 className="alarmTableText">{alarm.deviceName} (identifier: {alarm.deviceIdentifier})</h4>
                                                </td>
                                                <td>
                                                    {alarm.gatewayIdentifier === "mv" || alarm.gatewayIdentifier === "external" || alarm.gatewayIdentifier === "gateway"?
                                                        <p className="alarmTableText">The gateway</p>
                                                        :
                                                        alarm.gatewayIdentifier !== ""?
                                                            <p className="alarmTableText">device connected: {alarm.gatewayIdentifier}</p>
                                                            :
                                                            <p className="alarmTableText">-</p>
                                                    }
                                                </td>
                                                <td>
                                                    <p className="alarmTableText">-</p>
                                                </td>
                                                <td>
                                                    {alarm.datatype !== "Crash" ?
                                                        alarm.limiteType === 3 ?
                                                            <p>The device did not send points during {alarm.limite} minutes</p>
                                                            :
                                                        <p className="alarmTableText">{alarm.datatype} {alarm.limiteType === 1? "greater than " : "lower than "}
                                                            {alarm.datatype === "Temperature" ?
                                                                temperatureUnit === "F" ?
                                                                    (alarm.limite* 9 / 5) + 32
                                                                    :
                                                                    temperatureUnit === "K" ?
                                                                        alarm.limite+273.15
                                                                        :
                                                                        alarm.limite
                                                                :
                                                                alarm.limite}
                                                        </p>
                                                        :
                                                        <p className="alarmTableText">A crash has occurred</p>
                                                    }
                                                </td>
                                                <td>
                                                    <p className="alarmTableText">{moment(new Date(alarm.dateAlarm*1000)).format("YYYY MMMM DD, HH:mm")}</p>
                                                </td>
                                            </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            </div>
                        </div>
                    )
                })
                :
                <p className="alarmText">No Alarms released for the moment</p>
            }

            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">alarm successfully released</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Something went wrong</Alert>
            </Snackbar>
        </div>
    )
}

export default GetAlarms;