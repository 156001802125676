import React, {useContext, useEffect, useState} from 'react';
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import mqttRequests from "../../Utils/mqttRequests";
import moment from "moment";
import {MqttClientContext} from "../../context/MqttClientContext";
import "./Devices.scss";
import "leaflet/dist/leaflet.css"
import {useNavigate} from "react-router-dom";
import {SettingsContext} from "../../context/SettingsContext";
import {Slider} from "@mui/material";
import TemperatureYes from "../../assets/TemperatureYes.png";
import TemperatureNo from "../../assets/TemperatureNo.png";
import HumidityYes from "../../assets/HumidityYes.png";
import HumidityNo from "../../assets/HumidityNo.png";
import LineYes from "../../assets/LinesYes.png";
import LineNo from "../../assets/LinesNo.png";
import DotsYes from "../../assets/DotsYes.png";
import DotsNo from "../../assets/DotsNo.png";
import Cookies from "universal-cookie";

//the page to display device data
function ProfileData(props) {

    const cookies = new Cookies(null, {path: '/'});

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('deviceList');

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', () => {
            navigate("/deviceList")
        }, false);
    }, [navigate]);

    //the number of points display in the chart (1/1 1/2 1/3...) 1/3 -> 3
    const [numberReduced, setNumberReduce] = useState(1);

    //variables for time
    const [unitTime, setUnitTime] = useState("sec");
    //for the seconds of the last point
    const [maxTime, setMaxTime] = useState(0);
    //variable to have the first point timestamp
    const [firstPointTime, setFirstPointTime] = useState(0);
    //the number by each timestamp must be divided to have the time in min/h/days...
    const [timeDivisionNumber, setTimeDivisionNumber] = useState(1);

    //for line and dots display
    const [lineRender, setLineRender] = useState(true);
    const [shapeRender, setShapeRender] = useState(true);

    //variables for temperature
    const [temperature, setTemperature] = useState([]);
    const [temperatureDisplayed, setTemperatureDisplayed] = useState([]);
    //labels for temperature
    const [labelTemperature, setLabelTemperature] = useState("");

    //variables for humidity
    const [humidity, setHumidity] = useState([]);
    const [humidityDisplayed, setHumidityDisplayed] = useState([]);
    //labels for humidity
    const [labelHumidity, setLabelHumidity] = useState("");

    //for data display
    const [temperatureCheckbox, setTemperatureCheckbox] = useState(true);
    const [humidityCheckbox, setHumidityCheckbox] = useState(true);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the context for temperature unit
    const {temperatureUnit} = useContext(SettingsContext);

    //get the device
    let profile = cookies.get("profile");

    //set the first point in time
    useEffect(() => {
        setFirstPointTime(profile.startDate);
    }, [profile])

    //communicate with mqtt to get the profile points
    useEffect(() => {
        if(mqttClient !== null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getProfilePoints");
            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idProfile":"' + profile.idProfile + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getProfilePoints");
            //publish a demand to add a user
            mqttRequests.publish(mqttClient, "profile/getProfilePoints", data);

            //read incoming message and set the variables
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //the list of all devices in the db
                if(jsonParsed.temperature){
                    manageData(jsonParsed);
                    //the list of all different datatype in the db with id and name
                }

            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mqttClient])

    //return labels for the xAxis of all charts displaying the time in duration and in days/month/year
    function CustomizedTick(props){
        const { x, y, payload} = props;
        //find the duration between the first point and now
        let timestamp = payload.value;

        //set the actual time in date format
        let displayDate = new Date((firstPointTime + timestamp)*1000);
        //display the infos differently according to the duration of the data asked
        switch (true) {
            //less than 5 days : days + hours
            case (maxTime < 432000):
                displayDate = moment(displayDate).format('MM/DD-HH:mm');
                break;
            //less than 4 month : days
            case (maxTime < 10512008):
                displayDate = moment(displayDate).format('MM/DD');
                break;
            //less than 2.5 years : month + year
            case (maxTime < 78840000):
                displayDate = moment(displayDate).format('MM-YYYY');
                break;
            //2.5 years or more : year
            case (maxTime >= 78840000):
                displayDate = moment(displayDate).format('YYYY');
                break;
            default:
                break;
        }

        //return a value in duration and a value in date format
        return(
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0">
                        {Math.round(payload.value/timeDivisionNumber)}
                    </tspan>
                    <tspan textAnchor="middle" x="0" dy="20">
                        {displayDate}
                    </tspan>
                </text>
            </g>
        );
    }

    //returns the customized data when a user pass the mouse in a point in the chart
    const CustomTooltip = ({active, payload}) => {
        if(active && payload && payload.length){
            //if the user is on a point -> render the date formatted and the info (humidity, temperature...)
            return(
                <div className="deviceCustomTooltip">
                    <p>Time: {moment(new Date((payload[0].payload.timestamp+firstPointTime)*1000)).format('YYYY-MM-DD HH:mm')}</p>
                    <p>{`${Object.keys(payload[0].payload)[2]}: ${Object.values(payload[0].payload)[2]}`}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    //manage data
    const manageData = (data) => {
        //reset the slider change
        setNumberReduce(1);

        //find the latest data
        let totalTime = data.time[data.time.length-1];

        let divisionNumber = 1;
        //choose the unit for the time depending on the duration of the data (data during how much time)
        switch (true){
            //less than 2 min - unit in seconds
            case (totalTime < 120):
                break;
            //less than 5h - unit in minutes
            case (totalTime < 18000):
                setUnitTime("min");
                divisionNumber =60;
                break;
            //less than 5 days - unit in hours
            case (totalTime < 432000):
                setUnitTime("h");
                divisionNumber = 3600;
                break;
            //less than 180 days - unit in days
            case (totalTime < 15552000):
                setUnitTime("days");
                divisionNumber = 86400 ;
                break;
            //less than 3 years - unit in month
            case (totalTime < 94608000):
                setUnitTime("month");
                divisionNumber = 2628002;
                break;
            //more than 3 years - unit in year
            case (totalTime > 9460800):
                setUnitTime("year");
                divisionNumber = 31536000;
                break;
            default:
                break;
        }
        //set variable for divisionNumber
        setTimeDivisionNumber(divisionNumber);

        //set the max timestamp
        setMaxTime(data.time[data.time.length-1]);

        setTemperatureHumidity(data, divisionNumber);
    }

    //manage temperature and humidity if the device is not a teltonika
    const setTemperatureHumidity = (data, divisionNumber) => {
        //set the name of the content inside data

        //set the variable "temperature" with a table of all temperatures and time
        let tempTemperature = data["temperature"].map((id, index_value) =>{
            //return the correct temperature depending on the unit in the settings
            if(temperatureUnit === "C"){
                return {
                    time: data["time"][index_value]/divisionNumber,
                    timestamp: data["time"][index_value],
                    temperature: data["temperature"][index_value],
                }
            }else if(temperatureUnit === "F"){
                return {
                    time: data["time"][index_value]/divisionNumber,
                    timestamp: data["time"][index_value],
                    temperature: Math.round((((data["temperature"][index_value]*9/5)+32)*100)/100),
                }
            }else {
                return {
                    time: data["time"][index_value] / divisionNumber,
                    timestamp: data["time"][index_value],
                    temperature: Math.round(((data["temperature"][index_value]+273.15)*100)/100),
                }
            }
        });
        //set temperature = all points
        setTemperature(tempTemperature);

        //set the variable "humidity" with a table of all humidity and time
        let tempHumidity = data["humidity"].map((id, index_value) =>{
            return {
                time: data["time"][index_value]/divisionNumber,
                timestamp: data["time"][index_value],
                humidity: data["humidity"][index_value],
            }
        });

        //set humidity = all points
        setHumidity(tempHumidity);

        //if less than 500 points, display all points, if not, display max 500 points
        if(tempTemperature.length<500){
            setTemperatureDisplayed(tempTemperature);
            setHumidityDisplayed(tempHumidity);
        }else {
            let numberReduce;
            if(tempTemperature.length/500 <= 100){
                numberReduce = Math.ceil(tempTemperature.length/500);
            } else {
                numberReduce = 100;
            }
            displayLessPointsTemperatureHumidity(tempTemperature, tempHumidity, numberReduce);
            setNumberReduce(numberReduce);
        }

        //set labels
        setLabelTemperature("temperature");
        setLabelHumidity("humidity");
    }

    //modify the number of point in the variable displayed (1/1 1/2...) for temperature and humidity
    const displayLessPointsTemperatureHumidity = (temperatureTable, humidityTable, number) =>{
        //reduce the number of points for temperature
        let modulo = -1;
        let tempTemperature = temperatureTable.map((temp) => {
            modulo++;
            if(modulo%number===0){
                return {
                    time: temp.time,
                    timestamp: temp.timestamp,
                    temperature: temp.temperature,
                }
            }
            return undefined;
        });
        //remove the undefined data
        setTemperatureDisplayed(tempTemperature.filter(element => element !== undefined));

        //reduce the number of points for humidity
        let moduloHumidity = -1;
        let tempHumidity = humidityTable.map((humi) => {
            moduloHumidity++;
            if(moduloHumidity%number===0){
                return {
                    time: humi.time,
                    timestamp: humi.timestamp,
                    humidity: humi.humidity,
                }
            }
            return undefined;
        });
        //remove the undefined data
        setHumidityDisplayed(tempHumidity.filter(element => element !== undefined));
    }

    //actions when the user change the slider (modify the display of the slider)
    const handleSliderChange = (event, newValue) => {
        setNumberReduce(newValue);
    }

    //action when the user release the slider (modify the number of points in variables)
    const handleSliderChangeCommited = (event, newValue) => {
        displayLessPointsTemperatureHumidity(temperature, humidity, newValue);
    }

    //display the form, the button to download, the charts and the map
    return(
        <div className="baseMainDiv">
            <h2 className="baseTitle">Data from {profile.nameProfile}</h2>
            <div className="devicesFlexboxDivCenter">
                {temperatureCheckbox ?
                    <img src={TemperatureYes} onClick={() => setTemperatureCheckbox(!temperatureCheckbox)} alt="Temperature" style={{color:"#06c"}}/>
                    :
                    <img src={TemperatureNo} onClick={() => setTemperatureCheckbox(!temperatureCheckbox)} alt="Temperature"/>
                }
                {humidityCheckbox ?
                    <img src={HumidityYes} onClick={() => setHumidityCheckbox(!humidityCheckbox)} alt="Humidity" style={{color:"#06c"}}/>
                    :
                    <img src={HumidityNo} onClick={() => setHumidityCheckbox(!humidityCheckbox)} alt="Humidity"/>
                }
            </div>
            <br/>
            <div className="devicesFlexboxDivCenter">
                {lineRender ?
                    <img src={LineYes} onClick={() => setLineRender(!lineRender)} alt="Lines" style={{color:"#06c"}}/>
                    :
                    <img src={LineNo} onClick={() => setLineRender(!lineRender)} alt="Lines"/>
                }
                {shapeRender ?
                    <img src={DotsYes} onClick={() => setShapeRender(!shapeRender)} alt="Dots" style={{color:"#06c"}}/>
                    :
                    <img src={DotsNo} onClick={() => setShapeRender(!shapeRender)} alt="Dots"/>
                }
            </div>

            <div className="devicesFlexboxDivSlider">
                <p>How many points do you want to render: 1/</p>
                <Slider style={{width: 300, marginTop: 14, marginLeft: 15}} value={numberReduced} step={1} min={1} max={100} valueLabelDisplay={"on"} track={false} onChange={handleSliderChange} onChangeCommitted={handleSliderChangeCommited}/>
            </div>
            <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                <ScatterChart
                    margin={{
                        top: 20,
                        right: 40,
                        bottom: 20,
                        left: 45
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number" name="time" height={50} label={{value:"Time (" + unitTime + ")", position: "insideBottomLeft", dx:20, dy:-20}} domain={[0, "dataMax"]} tick={<CustomizedTick/>}/>
                    <YAxis className="deviceRechartsUserSelection" dataKey="temperature" type="number" name="temperature"
                           domain={[dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]}
                           label={{value:"Temperature (°C)", angle: -90, dy: 50, dx: -10, position: "insideLeft", fill:"#f00"}} yAxisId="left" stroke="#f00"/>
                    <YAxis className="deviceRechartsUserSelection" dataKey="humidity" type="number" name="humidity"
                           domain={[dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]}
                           label={{value:"Humidity (%)", angle: -90, dy: -35, dx: 10, position: "insideRight", fill:"#090"}} yAxisId="right" orientation="right" stroke="#090"/>
                    <Tooltip cursor={{ strokeDasharray: '3 3'}} content={<CustomTooltip />}/>
                    <Legend />
                    {temperatureCheckbox ?
                        <Scatter name={labelTemperature} data={temperatureDisplayed} line={{stroke: '#f00', strokeWidth: lineRender? 2 : 0}} shape={shapeRender? "circle" : null} legendType="circle" yAxisId="left" fill="#f00"/>
                        :
                        null
                    }
                    {humidityCheckbox ?
                        <Scatter name={labelHumidity} data={humidityDisplayed} line={{stroke: '#090', strokeWidth: lineRender? 2 : 0}} shape={shapeRender? "circle" : null} legendType="circle" yAxisId="right" fill="#090"/>
                        :
                        null
                    }


                </ScatterChart>
            </ResponsiveContainer>
            <div className="DeviceBackButton">
                <button onClick={() => navigate("/deviceList")} className="devicesButton">Back</button>
            </div>
        </div>
    )
}

export default ProfileData;