import React, {useContext, useEffect, useState} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import {useNavigate} from "react-router-dom";
import {Alert, Dialog, DialogActions, DialogTitle, Snackbar} from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import EditGroupUser from "./EditGroupUser";
import GetUserGroupUser from "./getUserGroupUser";
import GetGroupRight from "./getGroupRight";
import AddGroupUser from "./AddGroupUser";

//the page witch display all the user groups
function GetGroupUser(props) {

    //to navigate through the app
    let navigate = useNavigate();

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('groupManagement');

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', () => {
            setMenuClicked("home");
            navigate("/")
        }, false);
    }, [navigate, setMenuClicked]);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //set variable to get the data
    const [groupUser, setGroupUser] = useState([]);
    //set variable to display an error message if something goes wrong
    const [unknownError, setUnknownError] = useState(false);
    //set variable to re get the data
    const [reshow, setReshow] = useState(false);
    //set variable for the dialog (message "are you sure to hide")
    const[dialogOpen, setDialogOpen] = useState(false);
    //set variable to store the username
    const [name, setName] = useState("");
    //set variable for the action Hide/show/active/deactive
    const [action, setAction] = useState("");

    //variables to display the popups
    const [groupUserEdited, setGroupUserEdited] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errOneOpen, setErrOneOpen] = useState(false);
    const [errTwoOpen, setErrTwoOpen] = useState(false);
    const [errThreeOpen, setErrThreeOpen] = useState(false);
    const [successOpenAdd, setSuccessOpenAdd] = useState(false);
    const [errOneOpenAdd, setErrOneOpenAdd] = useState(false);

    const [allRights, setAllRights] = useState([]);
    const [rightsNotInGroup, setRightsNotInGroup]= useState([]);
    //set variable to get the data (ids)
    const [rightsInGroup, setRightsInGroup] = useState([]);
    //all the usernames of the user not in the group
    const [newUsernames, setNewUsernames] = useState([]);
    const [groupUserUser, setGroupUserUser] = useState([]);

    const [editGroup, setEditGroup] = useState('');
    const [groupAdd, setGroupAdd] = useState(false);

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getGroupUser");

            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUser");

            //publish a demand to get all the user groups
            mqttRequests.publish(mqttClient, "user/getGroupUser", data);


            //generate operation code
            const operationGetAllRights = mqttRequests.generateOperationCode("getRights");
            //create json to publish
            const dataAllRights = '{"operation":"' + operationGetAllRights + '", "source":"' + source + '"}';
            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getRights");
            //publish a demand to get all the rights
            mqttRequests.publish(mqttClient, "user/getRights", dataAllRights);

            //get users
            const operationUsers = mqttRequests.generateOperationCode("getUsers");
            const dataUsers = '{"operation":"' + operationUsers + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getUsers");
            mqttRequests.publish(mqttClient, "user/getUsers", dataUsers);

            //read incoming message and send response to a function to refactor them
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //if we get the group
                if(jsonParsed.UserGroupName){
                    if(jsonParsed.result===1){
                        refactorData(jsonParsed);
                    }else{
                        setUnknownError(true);
                    }
                //if it's the hide/show action
                }else if(jsonParsed.operation.includes("editGroupUser")){
                    switch (jsonParsed.result) {
                        case 1:
                            setGroupUserEdited(true);
                            setEditGroup('');
                            break;
                        case -1:
                            //display error popup
                            setErrOneOpen(true);
                            break;
                        case -2:
                            //display error popup
                            setErrTwoOpen(true);
                            break;
                        case -3:
                            //display error popup
                            setErrThreeOpen(true);
                            break;
                        default :
                            break;
                    }
                }else if(jsonParsed.operation.includes("getRights")){
                    refactorDataRights(jsonParsed);
                }else if(jsonParsed.operation.includes("getGroupRight")){
                    if(jsonParsed.result===1){
                        setRightsInGroup(jsonParsed.idRight);
                    }
                }else if(jsonParsed.operation.includes("addGroupUserUser")){
                    if(jsonParsed.nbUser > 0){
                        //display error -1 popup
                        setSuccessOpen(true);
                    } else {
                        //display error -2 popup
                        setErrTwoOpen(true);
                    }
                    //if it's a message from removeGroupUserUser
                }else if(jsonParsed.operation.includes("getUsers")){
                    handleGetUsers(jsonParsed);
                    //if it's a message from addGroupUserUser
                }else if(jsonParsed.operation.includes("getGroupUserUser")){
                    if(jsonParsed.result===1){
                        setGroupUserUser(jsonParsed.loginName);
                    }
                }else if(jsonParsed.operation.includes("addGroupUser")){
                    if(jsonParsed.idGroup>0){
                        //display success popup
                        setSuccessOpenAdd(true);
                    } else {
                        console.error('error : ' + jsonParsed.idGroup);
                        if(jsonParsed.idGroup==="-1"){
                            //display error -1 popup
                            setErrOneOpenAdd(true);
                        } else if(jsonParsed.idGroup==="-2"){
                            //display error -2 popup
                            setErrTwoOpen(true);
                        }
                    }
                }
            })
        }
    },[source, idAccount, mqttClient , reshow]);

    //handle the users
    const handleGetUsers = (data) => {
        let tempUsers = [];
        data.userLoginName.forEach((element, id) => {
            if(data.userHide[id] === 0){
                tempUsers.push(element);
            }
        })
        setNewUsernames(tempUsers);
    }

    //transform data to be usefull
    const refactorDataRights = (data) => {

        //set array of right name to display the names to add in the form
        setRightsNotInGroup(data.nameRights);

        //create a table of object with the id and the corresponding name for all the existing rights
        setAllRights(data.idRights.map((id, index_value) =>{
            return {
                id: data.idRights[index_value],
                name: data.nameRights[index_value],
            }
        }));
    }

    //transform 3 separates arrays in 1 object with all the values to make it easier to render
    const refactorData = (data) => {
        setGroupUser(data.UserGroupName.map((id, index_value) =>{
            return {
                name: data.UserGroupName[index_value],
                description: data.UserGroupDescr[index_value],
                hide: data.UserGroupHide[index_value],
                users : data.usersInGroup[data.idGroupUser[index_value]],
                rights : data.rightInGroup[data.idGroupUser[index_value]]
            }
        }));
    };

    //display the dialog "are you sure you want to do that ?"
    const handleClickHide = (action, name) => {
        //set the action and the name
        setAction(action);
        setName(name);

        //open the dialog
        setDialogOpen(true);
    }

    //manage the hide, show, activate and deactivate a user
    const hideShow = () => {
        //close the confirmation dialog
        setDialogOpen(false);

        //correct the action to use the correct name to interact with mqtt (subscribe and post in the right channel)
        let realAction = action + "GroupUser";

        //generate operation code
        const operation = mqttRequests.generateOperationCode(realAction);

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupUser":"' + name + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/" + realAction);

        //publish a demand to hide show activate or deactivate a user
        mqttRequests.publish(mqttClient, "user/" + realAction, data);

        //recharge data
        setReshow(!reshow);
    }

    //navigate to the page to edit the group
    const handleEdit = (groupUser) => {
        //set the userGroup infos to recover it in the edit page
        localStorage.setItem("groupUserName", groupUser.name);
        localStorage.setItem("groupUserDescription", groupUser.description);
        setEditGroup(groupUser.name);
    }

    //navigate to the page to manage the rights of the group
    const handleRights = (name) => {
        //set the userGroup infos to recover it in the rights page
        localStorage.setItem("groupUserName", name);
        setEditGroup('manageRights' + name);
    }

    //navigate to the page to manage users in the group
    const handleUserInGroup = (name) => {
        //set the userGroup infos to recover it in the user page
        localStorage.setItem("groupUserName", name);
        setEditGroup('manageUsers' + name);
    }

    return(
        <div className="userMainDiv">
            <h2 className="UserTitle">All user groups</h2>
            {unknownError?
                <p>Sorry, an error has occurred</p>
                :
                <div className="userDivAccordion">
                    <Accordion alwaysOpen>
                        {groupUser.sort((a,b) => a.name > b.name ? 1 : -1,).map(function (groupUser, id) {
                            return (
                                <div key={id}>
                                    <Accordion.Item eventKey={id}>
                                        <Accordion.Header>{groupUser.name}</Accordion.Header>
                                        <Accordion.Body className="userAccordionBody">
                                            <table className="userTableNoBorder">
                                                <tbody>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Group:</td>
                                                    <td className="baseTd">{groupUser.name}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Description:</td>
                                                    <td className="baseTd">{groupUser.description !== "" ? groupUser.description : "-"}</td>
                                                </tr>
                                                <tr className="baseTr">
                                                    <td className="deviceBoldSpan">Hide:</td>
                                                    <td className="baseTd">{groupUser.hide === 1? "Yes" : "No"}</td>
                                                </tr>
                                                {groupUser.users ?
                                                    groupUser.users.sort((a,b) => a > b ? 1 : -1,).map((element, subId)=>{
                                                        return(
                                                        <tr key={subId}>
                                                            {subId === 0 ?
                                                                <td className="deviceBoldSpan">Users:</td>
                                                                :
                                                                <td className="deviceBoldSpan"></td>
                                                            }
                                                            <td className="baseTd">- {element}</td>
                                                        </tr>
                                                    )
                                                })
                                                    :
                                                    <tr>
                                                        <td className="deviceBoldSpan">Users:</td>
                                                        <td className="baseTd">-</td>
                                                    </tr>
                                                }
                                                {groupUser.rights?
                                                    groupUser.rights.sort((a,b) => a > b ? 1 : -1,).map((element, subId)=>{
                                                        return(
                                                            <tr key={subId}>
                                                                {subId === 0 ?
                                                                    <td className="deviceBoldSpan">Rights:</td>
                                                                    :
                                                                    <td className="deviceBoldSpan"></td>
                                                                }
                                                                <td className="baseTd">- {element}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td className="deviceBoldSpan">Rights:</td>
                                                        <td className="baseTd">-</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td className="deviceBoldSpan"></td>
                                                    <td>
                                                        <button className="UserButton" onClick={() => handleRights(groupUser.name)}>Manage Rights</button>
                                                        <button className="UserButton" onClick={() => handleUserInGroup(groupUser.name)}>Manage Users</button>
                                                        <button className="UserButton" onClick={() => handleEdit(groupUser)}>Edit</button>
                                                        {groupUser.hide===0?
                                                            <button className="UserButton" onClick={() => handleClickHide("hide", groupUser.name)}>Hide user group</button>
                                                            :
                                                            <button className="UserButton" onClick={() => handleClickHide("show", groupUser.name)}>Show user group</button>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {editGroup === groupUser.name ?
                                                <div className="deviceEdit">
                                                    <EditGroupUser setEditGroup={setEditGroup}/>
                                                </div>
                                                :
                                                null
                                            }
                                            {editGroup === 'manageUsers' + groupUser.name ?
                                                <div className="deviceEdit">
                                                    <GetUserGroupUser setEditGroup={setEditGroup} newUsernames={newUsernames} groupUser={groupUserUser}/>
                                                </div>
                                                :
                                                null
                                            }
                                            {editGroup === 'manageRights' + groupUser.name ?
                                                <div className="deviceEdit">
                                                    <GetGroupRight setEditGroup={setEditGroup} allRights={allRights} rightsNotInGroup={rightsNotInGroup} rightsInGroup={rightsInGroup}/>
                                                </div>
                                                :
                                                null
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                            )
                        })}
                    </Accordion>
                    <button className="deviceButtonAdd" onClick={() => setGroupAdd(true)}>Add user group</button>
                    {groupAdd ?
                        <AddGroupUser setGroupAdd={setGroupAdd}/>
                        :
                        null
                    }
                </div>
            }
            <Snackbar open={groupUserEdited} autoHideDuration={4000} onClose={() => setGroupUserEdited(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">user group successfully edited</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - user group not found, try again later</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
            <Snackbar open={errThreeOpen} autoHideDuration={4000} onClose={() => setErrThreeOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Group name already exist</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">User(s) successfully added at group</Alert>
            </Snackbar>
            <Snackbar open={successOpenAdd} autoHideDuration={4000} onClose={() => setSuccessOpenAdd(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">user group successfully added</Alert>
            </Snackbar>
            <Snackbar open={errOneOpenAdd} autoHideDuration={4000} onClose={() => setErrOneOpenAdd(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - user group already exist</Alert>
            </Snackbar>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" className="deviceDialog">
                    {"Are you sure to " + action + " this user group ?"}
                </DialogTitle>
                <DialogActions className="deviceDialog">
                    <button className="UserButton" onClick={() => setDialogOpen(false)}>No</button>
                    <button className="UserButton" onClick={hideShow}>Yes</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GetGroupUser;