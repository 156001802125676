import React, {useContext} from 'react';
import './Kinetiks.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {UserInfoContext} from "../../context/UserInfoContext";
import Cookies from "universal-cookie";

//the page to edit a kinetic
function EditKinetic(props) {

    const cookies = new Cookies(null, {path: '/'});

    const setEditKinetic = props.setEditKinetic;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the device to edit
    const kinetic = cookies.get("kineticEdit");

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //select which data must be rendered depending on the id asked
    const getParameterKinetic = (kinetic, id, type) => {
        let response = "";
        kinetic.kinParameterKinetics.forEach((element) =>{
            if(element.idKinParameter === id){
                if(type === "string"){
                    response = element.valueString;
                }
                if(type === "int"){
                    response = element.valueInteger;
                }
                if(type === "float"){
                    response = element.valueFloat;
                }
            }
        })
        return response;
    }

    const Wl = "Warning limit (" + getParameterKinetic(kinetic,"20230210153643834021", "string") + "): " ;
    const Cl = "Critical limit (" + getParameterKinetic(kinetic,"20230210153643834021", "string") + "): " ;

    //the initial values for formik
    const initialValues = {
        name: kinetic.nameKinetics,
        description: kinetic.descrKinetics,
        warning: getParameterKinetic(kinetic, "20230210153551170002", "string"),
        critical: getParameterKinetic(kinetic, "20230210153546656001", "string"),
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
    });

    //method to edit a kinetic
    const submitMethod = (value) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("editKinetic");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameKinetics":"' +
            kinetic.nameKinetics + '", "newNameKinetics":"' + value.name + '", "newDescrKinetics":"' + value.description + '", "warningLimit":"' + value.warning + '", "criticalLimit":"' + value.critical + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editKinetic");

        //publish a demand to edit the kinetic
        mqttRequests.publish(mqttClient, "kinetic/editKinetic", data);

        //get the kinetics
        operation = mqttRequests.generateOperationCode("getKinetic");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getKinetic");
        mqttRequests.publish(mqttClient, "kinetic/getKinetic", data);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <FormikControl control="input" type="text" label={Wl} name="warning"/>
                            <FormikControl control="input" type="text" label={Cl} name="critical"/>
                            <button type="submit" className="kineticsButton">Validate</button>
                            <button type="button" className="kineticsButton" onClick={() => setEditKinetic('')}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default EditKinetic;