import React, {useContext, useEffect, useState, FormEvent} from 'react';
import './Mission.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {Alert, Snackbar} from "@mui/material";
import moment from "moment";

//the page to add some missions
function AddMission(props) {

    let missions = props.missions;
    let setMissionAdd = props.setMissionAdd;

    //variables to display the popups
    const[errMessage, setErrMessage] = useState(false);

    //set if startDate is displayed or not
    const [startDateDisplayed, setStartDateDisplayed] = useState(true);

    //checkbox to set mission start and stop date manually or automatic
    const [startDateCheckboxManually, setStartDateCheckboxManually] = useState(true);
    const [stopDateCheckboxManually, setStopDateCheckboxManually] = useState(true);

    //list of mission name + id
    const [missionNamesSelect, setMissionNameSelect] = useState([]);

    //the idUser and idAccount for mqtt
    const {idUser, idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        previousMission: "",
        nameMission: "",
        descriptionMission: "",
        dateStart: moment(new Date()).format('YYYY-MM-DD'),
        dateStop: moment(new Date()).format('YYYY-MM-DD'),
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        nameMission: Yup.string().required("Required"),
        dateStart: Yup.date().required("Required"),
        dateStop: Yup.date().required("Required")
    });

    //get the missions to have the list of all missions (for previous mission)
    useEffect(() => {
        if(source !==null){
            //generate operation code
            const operation = mqttRequests.generateOperationCode("getMissions");
            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getMissions");
            //publish a demand to get all the missions
            mqttRequests.publish(mqttClient, "mission/getMissions", data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, idUser, mqttClient]);

    //get the missions and put the id and the name in a sorted object for the "select" for previous mission
    useEffect(() => {
        //create an entry for the possibility to select no previous mission
        let tempObject = [{key: "None", value: ""}]
        //keep only the name and id under the field key value
        let tempSorted = [];
        if(missions.length !== 0){
            let temp = missions.map((mission) => {
                if(mission.stateMission === 1 || mission.stateMission === 2){
                    return {
                        key: mission.nameMission,
                        value: mission.idMission,
                    }
                }
                return undefined
            });
            //sort the data alphabetically and remove undefined
            tempSorted = [...temp.filter(element => element !== undefined)].sort((a,b) => {
                return a.key.localeCompare(b.key);
            });
        }
        setMissionNameSelect([...tempObject, ...tempSorted]);
    },[missions]);

    //method to add a mission in mqtt
    const submitMethod = (value) => {
        //check if the start date is before the stop date
        if(new Date(value.dateStop)-new Date(value.dateStart) < 0 && !stopDateCheckboxManually){
            setErrMessage(true)
        } else {
            //generate operation code
            const operation = mqttRequests.generateOperationCode("addMission");

            //get the timestamp for the dates at 0h00 in the time zone of the creation
            let startDate = new Date(value.dateStart).getTime()/1000 + new Date().getTimezoneOffset()*60;
            let stopDate = new Date(value.dateStop).getTime()/1000 + new Date().getTimezoneOffset()*60;

            let previousMission = null;

            //if the user want to manage the states manually, don't add any start or stop date
            if(startDateCheckboxManually){
                startDate = 0;
            }
            if(stopDateCheckboxManually){
                stopDate = 0;
            }

            //if a previous mission exist, set it and remove the start date
            if(value.previousMission !== ""){
                previousMission = value.previousMission;
                startDate = 0;
            }

            //create json to publish
            const data = '{"operation":"' + operation + '", "source":"' + source + '", "idUser":"' + idUser + '", "idAccount":"' + idAccount + '", "previousMission":"' +
                previousMission + '", "nameMission":"' + value.nameMission + '", "descrMission":"' + value.descriptionMission + '", "dateStart":"' + startDate
                + '", "dateStop":"' + stopDate + '", "stateMission":"' + 1 + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/addMission");
            //publish a demand to add a mission
            mqttRequests.publish(mqttClient, "mission/addMission", data);
        }
    };

    //listen to the changes on the form to display or not the field alphaaeging 2
    const handleOnChange = (event: FormEvent) => {
        if(event.target.id === "previousMission"){
            if(event.target.value !== ""){
                setStartDateDisplayed(false);
            }else {
                setStartDateDisplayed(true);
            }
        }
    };

    return(
        <div className="missionDivAdd">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form onChange={handleOnChange}>
                            {missionNamesSelect.length!==0?
                                <FormikControl control="select" label="Previous mission: " name="previousMission" options={missionNamesSelect}/>
                                :
                                null
                            }
                            <FormikControl control="input" type="text" label="Mission name: " name="nameMission"/>
                            <FormikControl control="textarea" type="text" label="Mission description: " name="descriptionMission"/>

                            {startDateDisplayed ?
                                <div className="missionFlexboxDivManagement">
                                    <input className="missionCheckbox" type="checkbox" id="startDate" name="startDate"
                                           onClick={() => setStartDateCheckboxManually(!startDateCheckboxManually)} checked={startDateCheckboxManually} readOnly="yes"/>
                                    <p className="missionFlexText">Manage start date Manually</p>
                                </div>
                                :
                                null
                            }
                            {startDateCheckboxManually === false && startDateDisplayed ?
                                <FormikControl control="input" type="date" label="Start date : " name="dateStart" />
                                :
                                null
                            }
                            <div className="missionFlexboxDivManagement">
                                <input className="missionCheckbox" type="checkbox" id="stopDate" name="stopDate"
                                       onClick={() => setStopDateCheckboxManually(!stopDateCheckboxManually)} checked={stopDateCheckboxManually} readOnly="yes"/>
                                <p className="missionFlexText">Manage stop date Manually</p>
                            </div>
                            {stopDateCheckboxManually === false ?
                                <FormikControl control="input" type="date" label="Stop date : " name="dateStop"/>
                                :
                                null
                            }

                            <button type="submit" className="missionButton">Validate</button>
                            <button type="button" className="missionButton" onClick={() => setMissionAdd(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
            <Snackbar open={errMessage} autoHideDuration={4000} onClose={() => setErrMessage(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - end date before begin date</Alert>
            </Snackbar>
        </div>
    )
}

export default AddMission;