import React, {useContext} from 'react';
import './Devices.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import Cookies from "universal-cookie";

//the page to edit a device
function EditDevice(props) {

    const cookies = new Cookies(null, {path: '/'});

    const setEdit = props.setEdit;

    //the device to edit
    const device = (cookies.get("deviceEdit"));

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        customName: device.customName,
        description: device.descrDevice,
        identifier: device.identifier,
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        customName: Yup.string().required("Required"),
    });

    //method to edit a device
    const submitMethod = (value) => {
        //generate operation code
        const operation = mqttRequests.generateOperationCode("editDevice");

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idDevice":"' +
            device.idDevice + '", "newCustomName":"' + value.customName + '", "newDescrDevice":"' + value.description + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editDevice");

        //publish a demand to edit the device
        mqttRequests.publish(mqttClient, "device/editDevice", data);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Custom name: " name="customName"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <button type="submit" className="devicesButton">Validate</button>
                            <button type="button" className="devicesButton" onClick={() => setEdit('')}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default EditDevice;