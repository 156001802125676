import React, {useContext, useEffect, useState} from 'react';
import './login.scss'
import {useNavigate} from "react-router-dom";
import {MqttClientContext} from "../../context/MqttClientContext";
import mqttRequests from "../../Utils/mqttRequests";

//the login page
function Login(props) {

    //to navigate through the app
    let navigate = useNavigate();

    //props to set some variables
    const setIdUser = props.setIdUser;
    const setIdAccount = props.setIdAccount;
    const setIdRight = props.setIdRight;

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('login');

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', () => {
            navigate("/")
            setMenuClicked("Home");
        }, false);
    }, [navigate, setMenuClicked]);

    //states for the content of the form fields
    const[login, setLogin] = useState('');
    const[password, setPassword] = useState('');
    const[accountToken, setAccountToken] = useState('');

    //display or not the error message
    const[authenticationFailed, setAuthenticationFailed] = useState(false);
    const[errorCode, setErrorCode] = useState(-2);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //manage the sending of the form and contact with mqtt
    const signInFunction = () => {

        //generate operation code
        let operation = mqttRequests.generateOperationCode("login");

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "accountToken":"' + accountToken + '", "username":"' + login + '", "password":"' + password + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/login");

        //publish a login test
        mqttRequests.publish(mqttClient, "user/login", data);

        //read incoming message and if all is ok, call the login method
        mqttRequests.incomingMessage((message) => {
            let jsonParsed = JSON.parse(message.toString());
            //verify that it's the correct answer
            if(jsonParsed.idAccount){
                if(jsonParsed.idAccount>0){
                    logIn(jsonParsed);
                } else {
                    console.error('error : ' + jsonParsed.idAccount);
                    setErrorCode(jsonParsed.idAccount);
                    setAuthenticationFailed(true);
                }
            }
        })
    }

    //set the constant for the user and travel to home menu and save them in the local storage
    const logIn = (message) =>{
        //store usefull infos in the local storage to keep persistence
        localStorage.setItem("idUser", message.idUser);
        localStorage.setItem("idAccount", message.idAccount);
        localStorage.setItem("idRight", JSON.stringify(message.idRight));

        //set the variables for the context
        setIdUser(message.idUser);
        setIdAccount(message.idAccount);
        setIdRight(message.idRight);

        //navigate to home page
        setMenuClicked("Home");
        navigate("/");
    }

    //valid the form if the user press the enter key
    const handleKeyUp = e => {
        //it triggers by pressing the enter key
        if (e.code === 'Enter') {
            signInFunction();
        }
    };

    return(
        <div className="loginMainDiv" onKeyUp={handleKeyUp}>
            <h2 className="loginTitle">Login</h2>
            <input type='text' placeholder='login' value={login} onChange={e => setLogin(e.currentTarget.value)} className="loginInput"/>
            <input type='password' placeholder='password' value={password} onChange={e => setPassword(e.currentTarget.value)} className="loginInput"/>
            <input type='number' placeholder='userToken' value={accountToken} onChange={e => setAccountToken(e.currentTarget.value)} className="loginInput"/>
            {authenticationFailed === true?
                errorCode !== "-3" ?
                    <p style={{color:"red"}}>Wrong login or password</p>
                    :
                    <p style={{color:"red"}}>Account inactive</p>
                : null
            }<button onClick={signInFunction} className="loginButton">Connection</button>
        </div>
    )
}

export default Login;