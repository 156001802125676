import React, {useContext} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";

//the page to change a password
function ChangePassword(props) {

    const setEditUser = props.setEditUser;

    //variables to display the popups
    const username = localStorage.getItem("username");

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        actualPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        actualPassword: Yup.string().required("Required"),
        newPassword: Yup.string()
            .required("Required")
            //min 8 characters
            .min(8, "Password must have at least 8 characters")
            //contains digits, lowercase and uppercase character
            .matches(/\d/, ">our password must have at least 1 digit character")
            .matches(/[A-Z]/, ">our password must have at least 1 uppercase character")
            .matches(/[a-z]/, ">our password must have at least 1 lowercase character"),
        newPasswordConfirmation: Yup.string().required("Please re-type your password")
            //oneOf is to match one of the values inside the array
            //ref is to get the value of the password
            .oneOf([Yup.ref("newPassword")], "passwords does not match"),
    });

    //method to communicate with mqtt
    const submitMethod = (value) => {

        //generate operation code
        const operation = mqttRequests.generateOperationCode("changePassword");

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "username":"' + username +
            '", "password":"' + value.actualPassword + '", "newPassword":"' + value.newPassword + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/changePassword");

        //publish a demand to change the password
        mqttRequests.publish(mqttClient, "user/changePassword", data);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="password" label="Actual password: " name="actualPassword"/>
                            <FormikControl control="input" type="password" label="New password: " name="newPassword"/>
                            <p className="userTextPassword">Must contain at least 1 uppercase, 1 lowercase and 1 numeric character. Minimum 8 characters.</p>
                            <FormikControl control="input" type="password" label="Repeat new password: " name="newPasswordConfirmation"/>
                            <button type="submit" className="UserButton">Validate</button>
                            <button type="button" className="UserButton" onClick={() => setEditUser('')}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ChangePassword;