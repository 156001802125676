import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider} from "@mui/material";
import {
    CartesianGrid,
    Legend,
    ReferenceArea,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {SettingsContext} from "../../context/SettingsContext";
import moment from "moment/moment";
import LineNo from '../../assets/LinesNo.png';
import LineYes from '../../assets/LinesYes.png';
import DotsNo from'../../assets/DotsNo.png';
import DotsYes from '../../assets/DotsYes.png';
import Cookies from 'universal-cookie';
import mqttRequests from "../../Utils/mqttRequests";
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";

//display the temperature of the data logger with the kinetics
function KineticsData(props) {

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('missions');

    const cookies = new Cookies(null, {path: '/'});

    //to navigate through the app
    let navigate = useNavigate();

    //back button effect
    useEffect(() => {
        window.addEventListener('popstate', () => {
            navigate("/manageMission")
        }, false);
    }, [navigate]);

    let [kinNumber, setKinNumber] = useState(0);

    //the infos from the previous page
    let device = JSON.parse(cookies.get("KinCalcDevice"));
    let kinCalcKinetics = JSON.parse(cookies.get("KinCalcKinetics"));
    let firstPointTime = parseInt(cookies.get("MissionFirstPointTime"));

    //the idAccount for mqtt
    const {idAccount, idUser, idRight} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the labels
    let [labelDL1, setLabelDL1] = useState("");
    let [labelDL2, setLabelDL2] = useState("");
    let [labelDL3, setLabelDL3] = useState("");
    let [labelDL4, setLabelDL4] = useState("");
    //the variables for the data logger points
    let [DL1, setDL1] = useState([]);
    let [DL1Displayed, setDL1Displayed] = useState([]);
    let [DL2, setDL2] = useState([]);
    let [DL2Displayed, setDL2Displayed] = useState([]);
    let [DL3, setDL3] = useState([]);
    let [DL3Displayed, setDL3Displayed] = useState([]);
    let [DL4, setDL4] = useState([]);
    let [DL4Displayed, setDL4Displayed] = useState([]);

    let [devices, setDevices] = useState([]);

    //bounds for the zoom
    const [boundsTemperatureYAxis, setBoundsTemperatureYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [boundsAlphaYAxis, setBoundsAlphaYAxis] = useState([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
    const [displayBoundsTemperature, setDisplayBoundsTemperature] = useState([0, 0]);
    const [displayBoundsAlpha, setDisplayBoundsAlpha] = useState([0, 0]);
    const [saveBoundsTemperature, setSaveBoundsTemperature] = useState([0, 0]);
    const [saveBoundsAlpha, setSaveBoundsAlpha] = useState([0, 0]);

    //checkbox for the different data loggers
    let [checkD1, setCheckD1] = useState(true);
    let [checkD2, setCheckD2] = useState(true);
    let [checkD3, setCheckD3] = useState(true);
    let [checkD4, setCheckD4] = useState(true);

    //for the slider and the display line and shape
    let [numberReduced, setNumberReduced] = useState([]);
    let [shapeRender, setShapeRender] = useState([]);
    let [lineRender, setLineRender] = useState([]);

    //variables to zoom
    const [stateZoom, setStateZoom] = useState({
        left: 0,
        right: 0,
        refAreaLeft: '',
        refAreaRight: '',
        animation: true,
    });

    //units for the time
    const [unitTime, setUnitTime] = useState("sec");
    const [maxTime, setMaxTime] = useState(0);
    //the number by each timestamp must be divided to have the time in min/h/days...
    const [timeDivisionNumber, setTimeDivisionNumber] = useState(1);

    //the context for temperature unit
    const {temperatureUnit} = useContext(SettingsContext);

    //return labels for the xAxis of all charts displaying the time in duration and in days/month/year
    function CustomizedTick(props) {
        const {x, y, payload} = props;
        //find the duration between the first point and now
        let timestamp = payload.value;

        //set the actual time in date format
        let displayDate = new Date((firstPointTime + timestamp) * 1000);
        //display the infos differently according to the duration of the data asked
        switch (true) {
            //less than 5 days : days + hours
            case (maxTime < 432000):
                displayDate = moment(displayDate).format('MM/DD-HH:mm');
                break;
            //less than 4 month : days
            case (maxTime < 10512008):
                displayDate = moment(displayDate).format('MM/DD');
                break;
            //less than 2.5 years : month + year
            case (maxTime < 78840000):
                displayDate = moment(displayDate).format('MM-YYYY');
                break;
            //2.5 years or more : year
            case (maxTime >= 78840000):
                displayDate = moment(displayDate).format('YYYY');
                break;
            default:
                break;
        }
        //return a value in duration and a value in date format
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0">
                        {Math.round(payload.value / timeDivisionNumber)}
                    </tspan>
                    <tspan textAnchor="middle" x="0" dy="20">
                        {displayDate}
                    </tspan>
                </text>
            </g>
        );
    }

    //returns the customized data when a user pass the mouse in a point in the chart
    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            //if the user is on a point -> render the date formatted and the info (humidity, temperature...)
            return (
                <div className="deviceCustomTooltip">
                    <p>Time: {moment(new Date((parseInt(payload[0].payload.timestamp) + firstPointTime) * 1000)).format('YYYY-MM-DD HH:mm')}</p>
                    <p>{kinCalcKinetics[kinNumber].unitName}{`: ${Object.values(payload[0].payload)[1]}`}</p>
                    <p>{`${Object.keys(payload[0].payload)[3]}: ${Math.round(Object.values(payload[0].payload)[3]*100)/100}`}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //subscribe to the channel to wait for the mqtt response (get all the devices for the current account)
            mqttRequests.subscribe(mqttClient, "device/list/" + idAccount);

            //demand to get the devices
            let operation = mqttRequests.generateOperationCode("getAllDevices");
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getAllDevices");
            mqttRequests.publish(mqttClient, "device/getAllDevices", data);

            //read incoming message and set the variables
            mqttRequests.incomingMessage((message) => {
                let jsonParsed = JSON.parse(message.toString());
                //the list of all devices in the db
                if(jsonParsed.devices) {
                    setDevices(jsonParsed.devices);
                    //the list of all different datatype in the db with id and name
                }
            })
        }
    },[source, idAccount, mqttClient]);

    //set the display temperature value of the fields for the bounds selecting
    useEffect(() => {
        let minTemp = 100000;
        let maxTemp = -100000;

        let minAlpha = 100000;
        let maxAlpha = -100000;
        if(checkD1){
            DL1Displayed.forEach((element) => {
                if (element.temperature < minTemp) {
                    minTemp = element.temperature;
                }
                if (element.temperature > maxTemp) {
                    maxTemp = element.temperature
                }
                if (element.alpha < minAlpha) {
                    minAlpha = element.alpha;
                }
                if (element.alpha > maxAlpha) {
                    maxAlpha = element.alpha
                }
            })
        }
        if(checkD2){
            DL2Displayed.forEach((element,) => {
                if (element.temperature < minTemp) {
                    minTemp = element.temperature;
                }
                if (element.temperature > maxTemp) {
                    maxTemp = element.temperature
                }
                if (element.alpha < minAlpha) {
                    minAlpha = element.alpha;
                }
                if (element.alpha > maxAlpha) {
                    maxAlpha = element.alpha
                }
            })
        }
        if(checkD3){
            DL3Displayed.forEach((element,) => {
                if (element.temperature < minTemp) {
                    minTemp = element.temperature;
                }
                if (element.temperature > maxTemp) {
                    maxTemp = element.temperature
                }
                if (element.alpha < minAlpha) {
                    minAlpha = element.alpha;
                }
                if (element.alpha > maxAlpha) {
                    maxAlpha = element.alpha
                }
            })
        }
        if(checkD4){
            DL4Displayed.forEach((element) => {
                if (element.temperature < minTemp) {
                    minTemp = element.temperature;
                }
                if (element.temperature > maxTemp) {
                    maxTemp = element.temperature
                }
                if (element.alpha < minAlpha) {
                    minAlpha = element.alpha;
                }
                if (element.alpha > maxAlpha) {
                    maxAlpha = element.alpha
                }
            })
        }

        setDisplayBoundsTemperature([Math.ceil(minTemp - 1), Math.floor(maxTemp + 1)]);
        setSaveBoundsTemperature([Math.ceil(minTemp - 1), Math.floor(maxTemp + 1)]);

        setDisplayBoundsAlpha([Math.ceil(minAlpha - 1), Math.floor(maxAlpha + 1)]);
        setSaveBoundsAlpha([Math.ceil(minAlpha - 1), Math.floor(maxAlpha + 1)]);

    }, [DL1Displayed, DL2Displayed, DL3Displayed, DL4Displayed, checkD1, checkD2, checkD3, checkD4]);

    //set the zoom
    useEffect(() => {
        let longestArray = findLongestArray(DL1, DL2, DL3 ,DL4)
        if(longestArray !== [] && longestArray.length !== 0){
            setStateZoom(existingValues => ({
                ...existingValues,
                right:  Math.ceil(longestArray[longestArray.length-1].timestamp),
            }));
        }
    }, [DL1, DL2, DL3, DL4]);

    //set the time division number, maxTime and unitTime
    useEffect(() => {
        //find the latest data
        let totalTime = Math.round(stateZoom.right - stateZoom.left);

        if (!isNaN(totalTime)) {
            let divisionNumber = 1;
            //choose the unit for the time depending on the duration of the data (data during how much time)
            switch (true) {
                //less than 2 min - unit in seconds
                case (totalTime < 120):
                    setUnitTime("min");
                    break;
                //less than 5h - unit in minutes
                case (totalTime < 18000):
                    setUnitTime("min");
                    divisionNumber = 60;
                    break;
                //less than 5 days - unit in hours
                case (totalTime < 432000):
                    setUnitTime("h");
                    divisionNumber = 3600;
                    break;
                //less than 180 days - unit in days
                case (totalTime < 15552000):
                    setUnitTime("days");
                    divisionNumber = 86400;
                    break;
                //less than 3 years - unit in month
                case (totalTime < 94608000):
                    setUnitTime("month");
                    divisionNumber = 2628002;
                    break;
                //more than 3 years - unit in year
                case (totalTime > 9460800):
                    setUnitTime("year");
                    divisionNumber = 31536000;
                    break;
                default:
                    break;
            }
            //set variable for divisionNumber
            setTimeDivisionNumber(divisionNumber);
            //update the maxTime;
            setMaxTime(totalTime);
        }

    }, [stateZoom.left, stateZoom.right]);

    //get the points and put it in the correct variables
    useEffect(() => {
        let count = 1;
        kinCalcKinetics[kinNumber].device.forEach((dataLogger) => {

            let totalTime = dataLogger.time[dataLogger.time.length-1];
            if (!isNaN(totalTime)) {
                //choose the unit for the time depending on the duration of the data (data during how much time)
                let divisionNumber = 1;
                switch (true) {
                    //less than 2 min - unit in seconds
                    case (totalTime < 120):
                        setUnitTime("min");
                        break;
                    //less than 5h - unit in minutes
                    case (totalTime < 18000):
                        setUnitTime("min");
                        divisionNumber = 60;
                        break;
                    //less than 5 days - unit in hours
                    case (totalTime < 432000):
                        setUnitTime("h");
                        divisionNumber = 3600;
                        break;
                    //less than 180 days - unit in days
                    case (totalTime < 15552000):
                        setUnitTime("days");
                        divisionNumber = 86400;
                        break;
                    //less than 3 years - unit in month
                    case (totalTime < 94608000):
                        setUnitTime("month");
                        divisionNumber = 2628002;
                        break;
                    //more than 3 years - unit in year
                    case (totalTime > 9460800):
                        setUnitTime("year");
                        divisionNumber = 31536000;
                        break;
                    default:
                        break;
                }
                //set variable for divisionNumber
                //update the maxTime;
                setMaxTime(Math.ceil(totalTime));
                setTimeDivisionNumber(divisionNumber);
            }
            //manage the temperature
            let tempDL = dataLogger.time.map((element, id) => {
                return {
                    timestamp: element,
                    alpha: dataLogger.alpha[id]* (kinCalcKinetics[kinNumber].yEnd-kinCalcKinetics[kinNumber].yInit) / (1 - kinCalcKinetics[kinNumber].AlphaAeging1) + (kinCalcKinetics[kinNumber].yInit - kinCalcKinetics[kinNumber].AlphaAeging1 * kinCalcKinetics[kinNumber].yEnd) / (1 - kinCalcKinetics[kinNumber].AlphaAeging1),
                    dAlpha: dataLogger.dalpha[id],
                    temperature: temperatureUnit === "C"?
                        dataLogger.temperature[id] - 273.15
                        : temperatureUnit === "F"?
                            ((dataLogger.temperature[id] - 273.15) *9/5) + 32
                            :
                            dataLogger.temperature[id],
                }
            })
            let numberReduce = 1;

            //Not teltonika
            if(device.identifier.length < 14){
                setDL1(tempDL);
                setLabelDL1(null);

                //if less than 200 points, display all points, if not, display max 500 points
                if (tempDL.length < 200) {
                    setDL1Displayed(tempDL.filter(element => element !== undefined));
                } else {
                    if (tempDL.length / 200 <= 100) {
                        numberReduce = Math.ceil(tempDL.length / 200);
                    } else {
                        numberReduce = 100;
                    }
                    displayLessPointsDL(tempDL.filter(element => element !== undefined), numberReduce, setDL1Displayed);
                    setNumberReduced(numberReduce);
                }
            } else {
                switch (count){
                    case 1:
                        setDL1(tempDL);
                        setLabelDL1(dataLogger.identifier_BLE);

                        //if less than 500 points, display all points, if not, display max 500 points
                        if (tempDL.length < 200) {
                            setDL1Displayed(tempDL.filter(element => element !== undefined));
                        } else {
                            if(tempDL.length !== 0){
                                if (tempDL.length / 200 <= 100) {
                                    numberReduce = Math.ceil(tempDL.length / 200);
                                } else {
                                    numberReduce = 100;
                                }
                            }
                            displayLessPointsDL(tempDL.filter(element => element !== undefined), numberReduce, setDL1Displayed);
                            if(tempDL.length !== 0){
                                setNumberReduced(numberReduce);
                            }
                        }
                        break;
                    case 2:
                        setDL2(tempDL);
                        setLabelDL2(dataLogger.identifier_BLE);

                        if (tempDL.length < 200) {
                            setDL2Displayed(tempDL.filter(element => element !== undefined));
                        } else {
                            if(tempDL.length !== 0){
                                if (tempDL.length / 200 <= 100) {
                                    numberReduce = Math.ceil(tempDL.length / 200);
                                } else {
                                    numberReduce = 100;
                                }
                            }
                            displayLessPointsDL(tempDL.filter(element => element !== undefined), Math.ceil(tempDL.length / 200), setDL2Displayed);
                            if(tempDL.length !== 0){
                                setNumberReduced(numberReduce);
                            }
                        }
                        break;
                    case 3:
                        setDL3(tempDL);
                        setLabelDL3(dataLogger.identifier_BLE);

                        if (tempDL.length < 200) {
                            setDL3Displayed(tempDL.filter(element => element !== undefined));
                        } else {
                            if(tempDL.length !== 0){
                                if (tempDL.length / 200 <= 100) {
                                    numberReduce = Math.ceil(tempDL.length / 200);
                                } else {
                                    numberReduce = 100;
                                }
                            }
                            displayLessPointsDL(tempDL.filter(element => element !== undefined), Math.ceil(tempDL.length / 200), setDL3Displayed);
                            if(tempDL.length !== 0){
                                setNumberReduced(numberReduce);
                            }
                        }
                        break;
                    case 4:
                        setDL4(tempDL);
                        setLabelDL4(dataLogger.identifier_BLE);

                        if (tempDL.length < 200) {
                            setDL4Displayed(tempDL.filter(element => element !== undefined));
                        } else {
                            if(tempDL.length !== 0){
                                if (tempDL.length / 200 <= 100) {
                                    numberReduce = Math.ceil(tempDL.length / 200);
                                } else {
                                    numberReduce = 100;
                                }
                            }
                            displayLessPointsDL(tempDL.filter(element => element !== undefined), Math.ceil(tempDL.length / 200), setDL4Displayed);
                            if(tempDL.length !== 0){
                                setNumberReduced(numberReduce);
                            }
                        }
                        break;
                    default:
                        break;
                }
                count++;
            }

        })
    // eslint-disable-next-line
    }, [kinNumber]);

    //modify the number of point in the variable displayed (1/1 1/2...) for longitude and latitude
    const displayLessPointsDL = (DLTable, number, actionDL) => {
        //reduce the number of points for battery
        let modulo = -1;
        let tempDL = DLTable.map((temp) => {
            modulo++;
            if (modulo % number === 0) {
                return {
                    timestamp: temp.timestamp,
                    alpha: temp.alpha,
                    dAlpha: temp.dAlpha,
                    temperature: temp.temperature,
                }
            }
            return undefined;
        });
        //actionDL = setDLDisplayed1/2/3/4, we remove undefined values
        actionDL(tempDL.filter(element => element !== undefined));
    }

    //manage the radio buttons
    const handleChange = (event) => {
        setKinNumber(event.target.value);
    }

    //actions when the user change the slider (modify the display of the slider)
    const handleSliderChange = (event, newValue) => {
        setNumberReduced(newValue);
    }

    //action when the user release the slider (modify the number of points in variables)
    const handleSliderChangeCommitted = (event, newValue) => {
        displayLessPointsDL(DL1, newValue, setDL1Displayed);
        displayLessPointsDL(DL2, newValue, setDL2Displayed);
        displayLessPointsDL(DL3, newValue, setDL3Displayed);
        displayLessPointsDL(DL4, newValue, setDL4Displayed);
    }

    //method to zoom, activate when the user release the click
    const zoom = () => {
        //recover refAreaLeft and refAreaRight from the state
        let {refAreaLeft, refAreaRight} = stateZoom;

        //if no ref right or the 2 are the same (user made a simple click on the chart), don't zoom and reset the refs
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: '',
                refAreaRight: '',
            }))
            return;
        }

        try {
            // set the right and the left area in the correct order (if the user select from right to left)
            if (refAreaLeft > refAreaRight) {
                [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
            }

            //select the first element and the last element which must be displayed
            let startElement = -1;
            let endElement = -1;
            let longestArray = findLongestArray(DL1Displayed, DL2Displayed, DL3Displayed ,DL4Displayed) ;

            longestArray.forEach((element, index) => {
                if (startElement === -1) {
                    if (element.timestamp >= refAreaLeft) {
                        startElement = index;
                    }
                }
                if (endElement === -1) {
                    if (element.timestamp >= refAreaRight) {
                        endElement = index;
                    }
                }
            })


            //restrain the number of data in the variables displayed temperature and humidity
            if (DL1Displayed.length !== 0) {
                setDL1Displayed(DL1Displayed.slice(startElement, endElement));
            }
            if (DL2Displayed.length !== 0) {
                setDL2Displayed(DL2Displayed.slice(startElement, endElement));
            }
            if (DL3Displayed.length !== 0) {
                setDL3Displayed(DL3Displayed.slice(startElement, endElement));
            }
            if (DL4Displayed.length !== 0) {
                setDL4Displayed(DL4Displayed.slice(startElement, endElement));
            }

            //set the new state of zoom
            setStateZoom({
                refAreaLeft: '',
                refAreaRight: '',
                left: refAreaLeft,
                right: refAreaRight,
                animation: true,
            });
            //in case of error, display it in the console
        } catch (err) {
            console.error(err);
        }
    }

    //method to find the longest array
    const findLongestArray = (arr1, arr2, arr3, arr4) => {
        let arr = [] ;
        if (arr1.length !== 0 || arr1 === []) {
            arr = arr1;
        }
        if(arr2.length !==0 || arr2 === []) {
            if(arr === []){
                arr = arr2;
            }
            else{
                if (arr2[arr2.length-1].timestamp > arr[arr.length-1].timestamp) {
                    arr = arr2 ;
                }
            }
        }
        if(arr3.length !== 0 || arr3 === []){
            if(arr === []){
                arr = arr3;
            }
            else{
                if (arr3[arr3.length-1].timestamp > arr[arr.length-1].timestamp) {
                    arr = arr3 ;
                }
            }
        }
        if(arr4.length !== 0 || arr4 === []){
            if(arr === []){
                arr = arr4;
            }
            else{
                if (arr4[arr4.length-1].timestamp > arr[arr.length-1].timestamp) {
                    arr = arr4 ;
                }
            }
        }
        return arr ;
    }

    //method to zoom out, reset the values at as they were before the zoom
    const zoomOut = () => {
        //reset the values for temperature and humidity
        if (DL1Displayed.length !== 0) {
            displayLessPointsDL(DL1, numberReduced, setDL1Displayed);
        }
        if (DL2Displayed.length !== 0) {
            displayLessPointsDL(DL2, numberReduced, setDL2Displayed);
        }
        if (DL3Displayed.length !== 0) {
            displayLessPointsDL(DL3, numberReduced, setDL3Displayed);
        }
        if (DL4Displayed.length !== 0) {
            displayLessPointsDL(DL4, numberReduced, setDL4Displayed);
        }
        let totalTime;
        //reset the total time
        let longestArray = findLongestArray(DL1, DL2, DL3, DL4);
        totalTime = longestArray[longestArray.length - 1].timestamp;

        //reset the division number
        let divisionNumber = 1;
        //choose the unit for the time depending on the duration of the data (data during how much time)
        switch (true) {
            //less than 2 min - unit in seconds
            case (totalTime < 120):
                setUnitTime("min");
                break;
            //less than 5h - unit in minutes
            case (totalTime < 18000):
                setUnitTime("min");
                divisionNumber = 60;
                break;
            //less than 5 days - unit in hours
            case (totalTime < 432000):
                setUnitTime("h");
                divisionNumber = 3600;
                break;
            //less than 180 days - unit in days
            case (totalTime < 15552000):
                setUnitTime("days");
                divisionNumber = 86400;
                break;
            //less than 3 years - unit in month
            case (totalTime < 94608000):
                setUnitTime("month");
                divisionNumber = 2628002;
                break;
            //more than 3 years - unit in year
            case (totalTime > 9460800):
                setUnitTime("year");
                divisionNumber = 31536000;
                break;
            default:
                break;
        }
        //update the divisionNumber
        setTimeDivisionNumber(divisionNumber);
        //update the maxTime;
        setMaxTime(totalTime);

        let right = Math.ceil(longestArray[longestArray.length-1].timestamp);

        //reset the state
        setStateZoom({
            left: 0,
            right: right,
            refAreaLeft: '',
            refAreaRight: '',
            animation: true,
        });
    }

    //activate when a user click down, set the state refAreaLeft
    const handleMouseDown = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaLeft: e.xValue,
            }))
        }
    }

    //activate when a user move the mouse in the plot, set the state refAreaRight
    const handleMouseMove = e => {
        if (e !== null) {
            setStateZoom(existingValues => ({
                ...existingValues,
                refAreaRight: e.xValue,
            }))
        }
    }

    //function to reset axis for temperature
    const resetYAxisTemperature = () => {
        setBoundsTemperatureYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsTemperature(saveBoundsTemperature);
    }

    //function to reset axis for alpha
    const resetYAxisAlpha = () => {
        setBoundsAlphaYAxis([dataMin => Math.ceil(dataMin - 1), dataMax => Math.floor(dataMax + 1)]);
        setDisplayBoundsAlpha(saveBoundsAlpha);
    }

    //changes when user modify the form
    const handleFormBoundsTemperatureFirst = e => {
        setBoundsTemperatureYAxis([parseInt(e.target.value), boundsTemperatureYAxis[1]]);
        setDisplayBoundsTemperature([parseInt(e.target.value), displayBoundsTemperature[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsTemperatureLast = e => {
        setBoundsTemperatureYAxis([boundsTemperatureYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsTemperature([displayBoundsTemperature[0], parseInt(e.target.value)]);
    }

    //changes when user modify the form
    const handleFormBoundsAlphaFirst = e => {
        setBoundsAlphaYAxis([parseInt(e.target.value), boundsAlphaYAxis[1]]);
        setDisplayBoundsAlpha([parseInt(e.target.value), displayBoundsAlpha[1]]);
    }

    //changes when user modify the form
    const handleFormBoundsAlphaLast = e => {
        setBoundsAlphaYAxis([boundsAlphaYAxis[0], parseInt(e.target.value)]);
        setDisplayBoundsAlpha([displayBoundsAlpha[0], parseInt(e.target.value)]);
    }

    //create the label of a device from his id
    const labelFromIdentifier = (id) => {
        console.log(id)
        console.log(devices)
        let chosenDevice = '';
        devices.forEach((element) => {
            if(element.identifier === id){
                chosenDevice = element;
            }
        });
        if(chosenDevice !== ''){
            return chosenDevice.customName + '(' + id + ')';
        }else {
            return id;
        }
    }

    return (
        <div className="baseMainDiv">
            <h2 className="baseTitle">Kinetics data for {device.customName} ({device.identifier})</h2>
            <FormControl>
                <FormLabel className="missionText">Kinetics: </FormLabel>
                <RadioGroup
                    className="missionText"
                    name="kinetics"
                    value={kinNumber}
                    onChange={handleChange}
                    row
                >
                    {kinCalcKinetics.map((kinetic, id) => {
                        return(
                            <>
                                <FormControlLabel value={id} control={<Radio sx={{'&.Mui-checked': {color:'#000'}}}/>} label={kinetic.nameKinetics}/>
                            </>
                        )
                    })}
                </RadioGroup>
            </FormControl>
            <div className="devicesFlexboxDivCenter">
                {lineRender ?
                    <img src={LineYes} onClick={() => setLineRender(!lineRender)} alt="Lines" style={{color:"#06c"}}/>
                    :
                    <img src={LineNo} onClick={() => setLineRender(!lineRender)} alt="Lines"/>
                }
                {shapeRender ?
                    <img src={DotsYes} onClick={() => setShapeRender(!shapeRender)} alt="Dots" style={{color:"#06c"}}/>
                    :
                    <img src={DotsNo} onClick={() => setShapeRender(!shapeRender)} alt="Dots"/>
                }
            </div>
            <div className="devicesFlexboxDivSlider">
                <p>How many points do you want to render: 1/</p>
                <Slider style={{width: 300, marginTop: 14, marginLeft: 15}} value={numberReduced} step={1}
                        min={1} max={100} valueLabelDisplay={"on"} track={false} onChange={handleSliderChange}
                        onChangeCommitted={handleSliderChangeCommitted}/>
            </div>
            <div className="devicesFlexboxDivSpaceEvenly">
                <button className="devicesButton" onClick={zoomOut}>Zoom out</button>
                <div className="devicesFlexboxDivLeft">
                    <p className="devicesFlexTextBoundsYaxis">Temperature bounds:</p>
                    <input type='number' value={displayBoundsTemperature[0]}
                           onChange={handleFormBoundsTemperatureFirst} className="devicesInput"/>
                    <input type='number' value={displayBoundsTemperature[1]}
                           onChange={handleFormBoundsTemperatureLast} className="devicesInput"/>
                    <button className="devicesButton" onClick={resetYAxisTemperature}>Reset</button>
                </div>
                <div className="devicesFlexboxDivLeft">
                    <p className="devicesFlexTextBoundsYaxis">Alpha bounds:</p>
                    <input type='number' value={displayBoundsAlpha[0]}
                           onChange={handleFormBoundsAlphaFirst} className="devicesInput"/>
                    <input type='number' value={displayBoundsAlpha[1]}
                           onChange={handleFormBoundsAlphaLast} className="devicesInput"/>
                    <button className="devicesButton" onClick={resetYAxisAlpha}>Reset</button>
                </div>
            </div>
            <ResponsiveContainer width="100%" height={550} className="deviceBackgroundWhite">
                <ScatterChart
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={zoom}
                    margin={{
                        top: 20,
                        right: 30,
                        bottom: 20,
                        left: 30
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis className="deviceRechartsUserSelection" dataKey="timestamp" type="number"
                           name="time"
                           domain={[stateZoom.left, stateZoom.right]} tick={<CustomizedTick/>}
                           height={50} label={{
                        value: "Time (" + unitTime + ")",
                        position: "insideBottomLeft",
                        dx: 20,
                        dy: -20
                    }}/>
                    <YAxis className="deviceRechartsUserSelection" dataKey="temperature" type="number"
                           name="temperature" domain={boundsTemperatureYAxis} allowDataOverflow={true}
                           label={temperatureUnit === "C"?
                               {
                                   value: "Temperature (°C)",
                                   angle: -90,
                                   dy: 50,
                                   dx: -10,
                                   position: "insideLeft",
                                   fill: "#f00"
                               }
                               :
                               temperatureUnit === "F"?
                                   {
                                       value: "Temperature (°F)",
                                       angle: -90,
                                       dy: 50,
                                       dx: -10,
                                       position: "insideLeft",
                                       fill: "#f00"
                                   }
                                   :
                                   {
                                       value: "Temperature (K)",
                                       angle: -90,
                                       dy: 50,
                                       dx: -10,
                                       position: "insideLeft",
                                       fill: "#f00"
                                   }
                           } yAxisId="left" stroke="#f00"/>
                    <YAxis className="deviceRechartsUserSelection" dataKey="alpha" type="number"
                           name="alpha" domain={boundsAlphaYAxis} allowDataOverflow={true}
                           label={{
                               value: "(" + kinCalcKinetics[kinNumber].unitName + ")",
                               angle: -90,
                               dy: -35,
                               dx: 10,
                               position: "insideRight",
                               fill: "#000"
                           }} yAxisId="right" orientation="right" stroke="#000"/>
                    <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip/>}/>
                    <Legend/>
                    {DL1.length !== 0 && checkD1 ?
                        <>
                            <Scatter name={labelDL1 !== null? labelFromIdentifier(labelDL1) : "Temperature"} data={DL1Displayed}
                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                     yAxisId="left" fill="#f00"/>
                            <Scatter name={labelDL1 !== null? labelFromIdentifier(labelDL1) : kinCalcKinetics[kinNumber].unitName}
                                     data={DL1Displayed} line={{stroke: '#000', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "circle" : null} legendType="circle"
                                     yAxisId="right" fill="#000"/>
                        </>
                        :
                        null
                    }
                    {DL2.length !== 0 && checkD2?
                        <>
                            <Scatter name={labelFromIdentifier(labelDL2)} data={DL2Displayed}
                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "triangle" : null} legendType="triangle"
                                     yAxisId="left" fill="#f00"/>
                            <Scatter name={labelFromIdentifier(labelDL2)} data={DL2Displayed}
                                     line={{stroke: '#000', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "triangle" : null} legendType="triangle"
                                     yAxisId="right" fill="#000"/>
                        </>
                        :
                        null
                    }
                    {DL3.length !== 0 && checkD3?
                        <>
                            <Scatter name={labelFromIdentifier(labelDL3)} data={DL3Displayed}
                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "square" : null} legendType="square"
                                     yAxisId="left" fill="#f00"/>
                            <Scatter name={labelFromIdentifier(labelDL3)} data={DL3Displayed}
                                     line={{stroke: '#000', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "square" : null} legendType="square"
                                     yAxisId="right" fill="#000"/>
                        </>
                        :
                        null
                    }
                    {DL4.length !== 0 && checkD4?
                        <>
                            <Scatter name={labelFromIdentifier(labelDL4)} data={DL4Displayed}
                                     line={{stroke: '#f00', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "star" : null} legendType="star"
                                     yAxisId="left" fill="#f00"/>
                            <Scatter name={labelFromIdentifier(labelDL4)} data={DL4Displayed}
                                     line={{stroke: '#000', strokeWidth: lineRender ? 2 : 0}}
                                     shape={shapeRender ? "star" : null} legendType="star"
                                     yAxisId="right" fill="#000"/>
                        </>
                        :
                        null
                    }
                    {stateZoom.refAreaLeft && stateZoom.refAreaRight ?
                        <ReferenceArea yAxisId="right" x1={stateZoom.refAreaLeft}
                                       x2={stateZoom.refAreaRight} strokeOpacity={0.3}/>
                        : null}
                </ScatterChart>
            </ResponsiveContainer>
            {device.identifier.length < 14 ?
                null
                :
                <div className="devicesFlexboxDivCenter">
                    <p className="devicePaddingTop">Devices displayed: </p>
                    {labelDL1 !== "0" && DL1.length !== 0 ?
                        <div className="devicesFlexboxDiv">
                            <input className="deviceCheckbox" type="checkbox" id="device1" name="device1"
                                   onClick={() => setCheckD1(!checkD1)} checked={checkD1}
                                   readOnly="yes"/>
                            <p className="deviceDisplayedText">{labelFromIdentifier(labelDL1)}</p>
                        </div>
                        :
                        null
                    }
                    {labelDL2 !== "0" && DL2.length !== 0 ?
                        <div className="devicesFlexboxDiv">
                            <input className="deviceCheckbox" type="checkbox" id="device2" name="device2"
                                   onClick={() => setCheckD2(!checkD2)} checked={checkD2}
                                   readOnly="yes"/>
                            <p className="deviceDisplayedText">{labelFromIdentifier(labelDL2)}</p>
                        </div>
                        :
                        null
                    }
                    {labelDL3 !== "0" && DL3.length !== 0 ?
                        <div className="devicesFlexboxDiv">
                            <input className="deviceCheckbox" type="checkbox" id="device3" name="device3"
                                   onClick={() => setCheckD3(!checkD3)} checked={checkD3}
                                   readOnly="yes"/>
                            <p className="deviceDisplayedText">{labelFromIdentifier(labelDL3)}</p>
                        </div>
                        :
                        null
                    }
                    {labelDL4 !== "0" && DL4.length !== 0 ?
                        <div className="devicesFlexboxDiv">
                            <input className="deviceCheckbox" type="checkbox" id="device4" name="device4"
                                   onClick={() => setCheckD4(!checkD4)} checked={checkD4}
                                   readOnly="yes"/>
                            <p className="deviceDisplayedText">{labelFromIdentifier(labelDL4)}</p>
                        </div>
                        :
                        null
                    }
                </div>
            }
        </div>
    );
}

export default KineticsData;