import React, {useContext} from 'react';
import './GeneralUser.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";

//the page to add some user groups
function AddGroupUser(props) {

    const setGroupAdd = props.setGroupAdd;

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
      name: "",
      description: ""
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
    });

    //method to communicate with mqtt
    const submitMethod = (value, {resetForm}) => {

        //generate operation code
        let operation = mqttRequests.generateOperationCode("addGroupUser");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "name":"' + value.name + '", "description":"' + value.description + '", "idAccount":"' + idAccount + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupUser");

        //publish a demand to add a user group
        mqttRequests.publish(mqttClient, "user/addGroupUser", data);

        resetForm({});

        //get group users again (refresh)
        operation = mqttRequests.generateOperationCode("getGroupUser");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupUser");
        mqttRequests.publish(mqttClient, "user/getGroupUser", data);
    };

    return(
        <div className="missionDivAdd">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="Name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <button type="submit" className="UserButton">Validate</button>
                            <button type="button" className="UserButton" onClick={() => setGroupAdd(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AddGroupUser;