import React, {useContext, useEffect, useState} from 'react';
import './Home.css'
import {useNavigate} from "react-router-dom";
import {UserInfoContext} from "../../context/UserInfoContext";
import axios from "axios";
import mqttRequests from "../../Utils/mqttRequests";
import {MqttClientContext} from "../../context/MqttClientContext";
import homeVideo from '../../assets/SLC_HomeScreen_Display.mp4' ;
import Missions from "../../assets/Mission.png";
import Devices from "../../assets/Devices.png";
import DeviceGroup from "../../assets/Group-Device.png";
import KineticLibrary from "../../assets/Kinetic-Library.png";
import Supervisions from "../../assets/Supervision.png";
import AlarmHistory from "../../assets/Alarm-History.png";
import AlarmRecipient from "../../assets/Alarm-Recipient.png";
import UserManagement from "../../assets/User-Management.png";
import GroupManagement from "../../assets/Group-Management.png";
import Settings from "../../assets/Settings.png";
import Login from "../../assets/Login.png";
import Logout from "../../assets/Logout.png";
import Cookies from "universal-cookie";

//the home page
function Home(props) {

    const cookies = new Cookies(null, { path: '/'});

    const setIdUser = props.setIdUser;
    const setIdAccount = props.setIdAccount;
    const setIdRight = props.setIdRight;
    const refreshSource = props.refreshSource;
    const setRefreshSource = props.setRefreshSource;

    const setMenuClicked = props.setMenuClicked;
    setMenuClicked('');

    const {mqttClient} = useContext(MqttClientContext);

    //the idUser and idAccount for mqtt
    const {idUser, idRight} = useContext(UserInfoContext);

    //variable to manage the display if the user have the rights
    const [UserManagementRight, setUserManagementRight] = useState(false);

    //connect to the client when the page opens
    useEffect(() => {
        if(idUser !== ""){
            if(idRight !== null){
                try{
                    setUserManagementRight(idRight.includes("02112221623298373532"));
                } catch (error) {
                    console.error(error);
                    setUserManagementRight(false);
                }
            }
        }
    }, [idRight, idUser]);

    const clickLogout = () => {
        mqttRequests.unSubscribe(mqttClient, "device/list/" + cookies.get("idAccount"));
        cookies.set("idUser", "");
        cookies.set("idAccount", "");
        cookies.set("idRight", "{}");
        setIdUser("");
        setIdAccount("");
        setIdRight("");
        setMenuClicked("Home");
        setRefreshSource(!refreshSource);
    }

    //{data: '%365=#13%USE=bertrod%PAS=geomoni#13%DIS=2df3db2db366447a221ced174ac0495b2c8035c7#13fnlg'},
    const sendConnection = () => {
        const response = axios.post(
            'https://www.akts.biz/transfer.php',
            '%365=\r%USE=bertrod\r%PAS=geomoni\rfnlg',
        )
    }

    const sendData = () => {
        console.log(cookies.get("devices"));
        //mqttRequests.publish(mqttClient, 'ruuviAgentTCP/writeFileFromTeltonika', '{"source":"teltonikaTCP","device":"E7EAD0724F08","modelDevice":"20230207161853922001","accountToken":0,"data":{"timestamps":[1729596386],"temperature":[34.46],"humidity":[31.2825],"battery":[2992],"movement":[205],"accelerationX":[0.156],"accelerationY":[1.032],"accelerationZ":[0.036],"macGateway":["863540063169099"],"atmPressure":[96620]}}');
    }


    //back button effect
    window.addEventListener('popstate', (event) => {
        navigate("/")
    }, false);

    //to navigate through the app
    let navigate = useNavigate();

    return(
        <>
            <div className="HomeMainDiv">
                <h2 className="homeTitle">Welcome to</h2>
                <p className="homeText">Universal shelf life monitoring system</p>
                <video autoPlay loop muted id='video' className='HomeVideo'>
                    <source src={homeVideo} type='video/mp4'/>
                </video>
                {idUser === "20221222164431542001"?
                    <button onClick={sendConnection}>send connection</button>
                    :
                    null
                }
                {idUser === "20221222164431542001"?
                    <button onClick={sendData}>lance la méthode</button>
                    :
                    null
                }

            </div>
            <div className="HomeMobileDiv">
                <h2 className="baseTitle">Universal shelf life monitoring system</h2>
                <div className="HomeFlex">
                    {idUser !== ""?
                        <>
                            <img className="devicesimg" src={Missions} onClick={() => navigate("/missions")} alt="Missions"/>
                            <img className="devicesimg" src={Devices} onClick={() => navigate("/deviceList")} alt="Devices"/>
                            <img className="devicesimg" src={DeviceGroup} onClick={() => navigate("/getGroupDevice")} alt="Device group"/>
                            <img className="devicesimg" src={KineticLibrary} onClick={() => navigate("/getKinetics")} alt="Kinetic Library"/>
                            <img className="devicesimg" src={Supervisions} onClick={() => navigate("/supervision")} alt="Supervisions"/>
                            <img className="devicesimg" src={AlarmHistory} onClick={() => navigate("/alarms")} alt="Alarms history"/>
                            <img className="devicesimg" src={AlarmRecipient} onClick={() => navigate("/recipient")} alt="Alarm recipients"/>
                            {UserManagementRight?
                                <>
                                    <img className="devicesimg" src={UserManagement} onClick={() => navigate("/userManagement")} alt="User management"/>
                                    <img className="devicesimg" src={GroupManagement} onClick={() => navigate("/groupManagement")} alt="group management"/>
                                    <img className="devicesimg" src={Settings} onClick={() => navigate("/settings")} alt="Settings"/>
                                </>
                                :
                                null
                            }
                        </>
                        :
                        null
                    }
                    {idUser === "" ?
                        <img className="devicesimg" src={Login} onClick={() => navigate("/login")} alt="Login"/>
                        :
                        <img className="devicesimg" src={Logout} onClick={clickLogout} alt="Lgout"/>
                    }
                </div>
            </div>
        </>

    )
}

export default Home;