import React, {useContext, useState} from 'react';
import './Devices.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import {Alert, Snackbar} from "@mui/material";

//the page to add some devices
function AddDevice(props) {

    const setDeviceAdd = props.setDeviceAdd;
    const modelOptions = props.modelOptions;

    //variables to display the popups
    const[successOpen, setSuccessOpen] = useState(false);
    const[errOneOpen, setErrOneOpen] = useState(false);
    const[errTwoOpen, setErrTwoOpen] = useState(false);

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        identifier: "",
        idModel: "",
        customName: "",
        description: "",
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        identifier: Yup.string().required("Required"),
        idModel: Yup.string().required("Required"),
        customName: Yup.string().required("Required"),
    });

    //method to add a device
    const submitMethod = (value, {resetForm}) => {
        //generate operation code
        const operation = mqttRequests.generateOperationCode("addDevice");

        //create json to publish
        const data = '{"operation":"' + operation + '", "source":"' + source + '", "idModel":"' + value.idModel + '", "identifier":"' +
            value.identifier + '", "customname":"' + value.customName + '", "idAccount":"' + idAccount + '", "description":"' + value.description + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/addDevice");

        //publish a demand to add a device
        mqttRequests.publish(mqttClient, "device/addDevice", data);

        resetForm({});
        setDeviceAdd(false);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form className="deviceForm">
                            <FormikControl control="input" type="text" label="Identifier: " name="identifier"/>
                            <FormikControl control="select" label="ID Model: " name="idModel" options={modelOptions}/>
                            <FormikControl control="input" type="text" label="Custom name: " name="customName"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <button type="submit" className="devicesButton">Validate</button>
                            <button type="button" className="devicesButton" onClick={() => setDeviceAdd(false)}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>
            <Snackbar open={successOpen} autoHideDuration={4000} onClose={() => setSuccessOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Device successfully added</Alert>
            </Snackbar>
            <Snackbar open={errOneOpen} autoHideDuration={4000} onClose={() => setErrOneOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - Identifier already exist</Alert>
            </Snackbar>
            <Snackbar open={errTwoOpen} autoHideDuration={4000} onClose={() => setErrTwoOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="error">Error - something went wrong, try again later</Alert>
            </Snackbar>
        </div>
    )
}

export default AddDevice;