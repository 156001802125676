import React, {useContext, useEffect, useState} from 'react';
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import mqttRequests from "../../Utils/mqttRequests";
import "./Devices.scss"
import deleteButton from '../../assets/delete.png'
import {
    Alert,
    Checkbox, checkboxClasses,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select, Snackbar
} from "@mui/material";
import Cookies from "universal-cookie";

//the page witch display all the devices in a group
function GetGroupDeviceDevice(props) {

    const cookies = new Cookies(null, {path: '/'});

    let setEditGroupDevice = props.setEditGroupDevice;
    let allDevices = props.allDevices;

    const [devicesInGroup, setDevicesInGroup] = useState(props.devicesInGroup);

    useEffect(() => {
        let tempDevice = [];
        props.devicesInGroup.forEach((device) => {
            allDevices.forEach((element) => {
                if (element.idDevice === device){
                    tempDevice.push(element.customName + ' (' + element.identifier + ')');
                }
            })
        })
        setDevicesInGroup(tempDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.devicesInGroup])

    //get the name of the group from cookies
    let nameGroupDevice = cookies.get("groupNameManage");

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //variable to rerender the data
    const [reshow, setReshow] = useState(false);
    //variable to store all devices not in the group for the select (device object)
    const [deviceNotInGroup, setDeviceNotInGroup] = useState(props.allDevices);
    //variable to set which device are selected to add (device object)
    const [deviceToAdd, setDeviceToAdd] = useState([]);
    //variable to set if the form is visible or not
    const [formVisible, setFormVisible] = useState(false);
    //display the warning popup if nothing to add in group
    const [warnEmptyOpen, setWarnEmptyOpen] = useState(false);


    //method to communicate with mqtt
    useEffect(() => {
        if(source !==null){
            //generate operation code
            let operation = mqttRequests.generateOperationCode("getGroupDeviceDevice");

            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupDevice":"' + nameGroupDevice + '"}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupDeviceDevice");

            //publish a demand to get the devices in the group
            mqttRequests.publish(mqttClient, "device/getGroupDeviceDevice", data);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[source, reshow]);

    //function to remove a device from the group
    const handleDelete = (name) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("removeGroupDeviceDevice");

        let id;
        allDevices.forEach((element) => {
            console.log(element)
            if (element.identifier === name.substring(name.indexOf('(') +1, name.lastIndexOf(')'))){
                console.log(element)
                id =element.idDevice;
            }
        })

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '", "nameGroupDevice":"' +
            nameGroupDevice + '", "idDevice":"' + id + '"}';

        console.log(JSON.parse(data))

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/removeGroupDeviceDevice");

        //publish a demand to delete a device
        mqttRequests.publish(mqttClient, "device/removeGroupDeviceDevice", data);

        //get groupDevices
        operation = mqttRequests.generateOperationCode("getGroupDevice");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupDevice");
        mqttRequests.publish(mqttClient, "device/getGroupDevice", data);

        //rerender data, if not, we don't see the deletion
        setReshow(!reshow);
    }

    //filter the devices which are not in the group and display the form or the warning if the form is empty
    const handleDisplayForm = () => {

        let tempDevice = [];
        devicesInGroup.forEach((device) => {
            allDevices.forEach((element) => {
                if(element.identifier === device.substring(device.indexOf('(') +1, device.lastIndexOf(')'))){
                    tempDevice.push(element.idDevice);
                }
            })
        })

        //select all the devices which ar not in the group
        let tempDeviceNotInGroup = deviceNotInGroup.filter( (element) => !tempDevice.includes(element.idDevice));
        setDeviceNotInGroup(tempDeviceNotInGroup);

        //if no one left to add show the warning, else, show the form
        if(tempDeviceNotInGroup.length === 0){
            setWarnEmptyOpen(true);
        }else {
            setFormVisible(true);
        }

    }

    //add the devices in the group
    const handleAddDevices = () => {
        if(deviceToAdd.length!==0){
            //generate operation code
            let operation = mqttRequests.generateOperationCode("addGroupDeviceDevice");

            //create a variable with all the idDevices of the devices based on their identifier (devicesToAdd have identifiers, allDevices have complete devices)
            let idDevices = JSON.stringify(deviceToAdd.map((element) => {
                let tempObject = allDevices.find(obj => {
                    return obj.identifier === element
                });
                return tempObject.idDevice;
            }));

            //create json to publish
            let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' +
                idAccount + '", "nameGroupDevice":"' + nameGroupDevice + '", "devices":' + idDevices + '}';

            //subscribe to the channel to wait for the mqtt response
            mqttRequests.subscribe(mqttClient, "source/" + source + "/addGroupDeviceDevice");

            //publish a demand to add devices to a group
            mqttRequests.publish(mqttClient, "device/addGroupDeviceDevice", data);

            //get groupDevices
            operation = mqttRequests.generateOperationCode("getGroupDevice");
            data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
            mqttRequests.subscribe(mqttClient, "source/" + source + "/getGroupDevice");
            mqttRequests.publish(mqttClient, "device/getGroupDevice", data);

            //hide the form and empty it
            setFormVisible(false);
            setDeviceToAdd([]);

            //reshow data
            setReshow(!reshow);
        }
    }

    //manage the changes in the form
    const handleChange = (event) => {
        const {
            target : {value},
        } = event;
        setDeviceToAdd(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return(
        <div>
            <table className="baseTableNoBorder">
                <tbody>
                {devicesInGroup.sort((a,b) => a > b ? 1 : -1,).map((device, id) => {
                    return (
                        <tr className="baseTr" key={id}>
                            <td className="deviceBoldSpan"></td>
                            <td className="baseTdFlex">
                                <span>{device}</span>
                                <img src={deleteButton} alt="X" className="deviceDeleteButton" onClick={() => handleDelete(device)}/>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {!formVisible ?
                <>
                    <button className="devicesButtonAddDevice" onClick={handleDisplayForm}>add devices to group</button>
                    <button className="devicesButton" onClick={() => setEditGroupDevice('')}>Cancel</button>
                </>
                :
                <div className="userFormControl">
                    <FormControl sx={{width: 1}} className="deviceSelect">
                        <InputLabel id="selectMultipleDevices" className="deviceInputLabel">Select Devices</InputLabel>
                        <Select
                            id="selectDevice"
                            multiple
                            value={deviceToAdd}
                            onChange={handleChange}
                            input={<OutlinedInput label="Select devices" sx={{color:'#fff'}}/>}
                            renderValue={(selected) => selected.join(', ')}
                            className="deviceSelect"
                            inputProps={{MenuProps:{PaperProps:{sx:{backgroundColor:'#aaa'}}}}}
                        >
                            {deviceNotInGroup.sort((a,b) => a.customName > b.customName ? 1 : -1,).map((device) => (
                                <MenuItem key={device.idDevice} value={device.identifier} className="deviceMenuItem">
                                    <Checkbox checked={deviceToAdd.indexOf(device.identifier) > -1} sx={{[`&.${checkboxClasses.checked}`]: {color:'#000'}}}/>
                                    <ListItemText primary={device.customName + " (" + device.identifier + ")"} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br/>
                    <button className="devicesButton" onClick={handleAddDevices}>Add</button>
                    <button className="devicesButton" onClick={() => setFormVisible(false)}>Cancel</button>
                </div>
            }
            <Snackbar open={warnEmptyOpen} autoHideDuration={4000} onClose={() => setWarnEmptyOpen(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="warning">All devices are already in this group</Alert>
            </Snackbar>
        </div>
    )
}

export default GetGroupDeviceDevice;