import React, {useContext} from 'react';
import './Devices.scss'
import {MqttClientContext} from "../../context/MqttClientContext";
import {UserInfoContext} from "../../context/UserInfoContext";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import mqttRequests from "../../Utils/mqttRequests";
import FormikControl from "../FormTemplate/FormikControl";
import Cookies from "universal-cookie";

//the page to edit a device group
function EditGroupDevice(props) {

    const cookies = new Cookies(null, {path: '/'});

    let setEditGroupDevice = props.setEditGroupDevice

    //the group to edit
    const group = cookies.get("groupDeviceEdit");

    //the idAccount for mqtt
    const {idAccount} = useContext(UserInfoContext);

    //the mqtt client and source
    const {mqttClient, source} = useContext(MqttClientContext);

    //the initial values for formik
    const initialValues = {
        name: group.groupName,
        description: group.description,
    };

    //the yup validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
    });

    //method to edit the group
    const submitMethod = (value) => {
        //generate operation code
        let operation = mqttRequests.generateOperationCode("editGroupDevice");

        //create json to publish
        let data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' +
            idAccount + '", "nameGroupDevice":"' + group.groupName + '", "newName":"' + value.name + '", "newDescr":"' + value.description + '"}';

        //subscribe to the channel to wait for the mqtt response
        mqttRequests.subscribe(mqttClient, "source/" + source + "/editGroupDevice");

        //publish a demand to edit the group
        mqttRequests.publish(mqttClient, "device/editGroupDevice", data);

        operation = mqttRequests.generateOperationCode("getGroupDevice");
        data = '{"operation":"' + operation + '", "source":"' + source + '", "idAccount":"' + idAccount + '"}';
        mqttRequests.publish(mqttClient, "device/getGroupDevice", data);
    };

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitMethod}
            >
                {() => {
                    return(
                        <Form>
                            <FormikControl control="input" type="text" label="New name: " name="name"/>
                            <FormikControl control="textarea" type="text" label="Description: " name="description"/>
                            <button type="submit" className="devicesButton">Validate</button>
                            <button type="button" className="devicesButton" onClick={() => setEditGroupDevice('')}>Cancel</button>
                        </Form>
                    )
                }}
            </Formik>

        </div>
    )
}

export default EditGroupDevice;